import { Theme, alpha } from '@mui/material'

export const gameListWrapper = {
  pt: '10px',
  display: 'flex',
  gap: '12px',
  overflowY: 'auto',
  pb: '24px',
  pl: '15px',
}

export const gameWrapper = [
  {
    borderRadius: '12px',
    boxShadow: '1px 1px 15px 0px rgba(29, 45, 78, 0.10)',
    height: '260px',
    display: 'flex',
    overflow: 'visible',
  },
  (theme: Theme) => (theme.device.isMobile ? { width: '160px' } : { width: '179px' }),
]

export const gameImage = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  width: '100%',
  height: '180px',
  borderTopRightRadius: '12px',
  borderTopLeftRadius: '12px',
}

export const gameDescription = { p: '10px 12px 14px', height: 'fit-content' }

export const gameName = {
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

export const gameFlames = { display: 'flex', alignItems: 'center', gap: '8px', pt: '8px' }

export const gameFlamesImage = { height: '20px', width: 'auto' }

export const gameFlamesDivider = {
  height: '14px',
  width: '1px',
  background: (theme: Theme) => alpha(theme.palette.common.darkBlue, 0.2),
}

export const ageRecommendation = { display: 'flex', alignItems: 'center', gap: '5px' }
export const ageRecommendationImage = {
  height: 'auto',
  width: '20px',
}
