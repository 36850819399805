export const enum FormFieldType {
  TEXT = 'text',
  EMAIL = 'email',
  PHONE = 'tel',
  PASSWORD = 'password',
  SELECT = 'select',
  DATE_PICKER = 'date_picker',
  FILE_UPLOADER = 'file_uploader',
  RADIO_GROUP = 'radio_group',
  CHECKBOX = 'checkbox',
}
