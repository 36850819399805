import { styled } from '@mui/material'

export const Ul = styled('ul')``
export const Li = styled('li')``
export const Img = styled('img')``
export const Form = styled('form')``
export const Main = styled('main')``
export const Video = styled('video')``
export const Header = styled('header')``
export const Footer = styled('footer')``
export const Picture = styled('picture')``
