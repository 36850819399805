import { FC } from 'react'

import { InputLabel as BaseInputLabel } from '@mui/material'

import { BaseFormFieldProps } from '@/components/forms/FormField'

import { inputLabelStyles } from './InputLabel.styles'

interface InputLabelProps extends Pick<BaseFormFieldProps, 'id' | 'label'> {}

const InputLabel: FC<InputLabelProps> = ({ id, label }) => {
  if (!label) {
    return null
  }

  return (
    <BaseInputLabel htmlFor={id} sx={inputLabelStyles} shrink>
      {label}
    </BaseInputLabel>
  )
}

export default InputLabel
