import axios from 'axios'

import { handleResponse } from './utils'

export const gamesApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_KINJO_GAME_SERVICE_API_KEY
      : '/game-service/api/v1/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

gamesApi.interceptors.response.use(handleResponse)
