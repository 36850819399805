import { FC } from 'react'

import { CircularProgress } from '@mui/material'

const Loader: FC = () => {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <CircularProgress sx={{ color: 'common.darkBlue' }} />
    </div>
  )
}

export default Loader
