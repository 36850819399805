import { useEffect, useCallback } from 'react'

interface ResizeObserverProps {
  handler: () => void
}

export const useResizeObserver = ({ handler }: ResizeObserverProps) => {
  const orientationChangeHandler = useCallback(() => {
    setTimeout(() => dispatchEvent(new Event('resize')), 500)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handler, false)
    window.addEventListener('orientationchange', orientationChangeHandler, false)
    handler()

    return () => {
      window.removeEventListener('resize', handler, false)
      window.removeEventListener('orientationchange', orientationChangeHandler, false)
    }
  }, [handler, orientationChangeHandler])
}
