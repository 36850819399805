import { Theme, SxProps } from '@mui/material'

export const mergeSx = (
  styles1: SxProps<Theme> | undefined = {},
  styles2: SxProps<Theme> | undefined = {},
) => {
  const styles1Array = Array.isArray(styles1) ? styles1 : [styles1]
  const styles2Array = Array.isArray(styles2) ? styles2 : [styles2]
  return [...styles1Array, ...styles2Array]
}
