/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

const ErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ fill: 'none', stroke: 'inherit', ...props.sx }}>
      <circle cx="12.0001" cy="12" r="9.36389" strokeWidth="1.7" />
      <path d="M12 7.12497V12.725" strokeLinecap="round" strokeWidth="1.7" />
      <circle color="currentColor" cx="12" cy="16" fill="currentColor" r="0.85" strokeWidth="0.3" />
    </SvgIcon>
  )
}

export default ErrorIcon
