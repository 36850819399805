import axios from 'axios'

import { handleResponse } from './utils'

export const paymentApi = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_KINJO_HOST_API_KEY,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

paymentApi.interceptors.response.use(handleResponse)
