import { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import { EnqueueSnackbar } from 'notistack'
import { FirebaseError } from 'firebase/app'

interface BackendErrorResponseDetails {
  [key: string]: string
}
interface BackendErrorResponse {
  detail?: string
  details?: BackendErrorResponseDetails
  message: string
  timestamp: number
}

export enum ParsedRestErrorType {
  ERROR = 'ERROR',
  MESSAGE = 'MESSAGE',
  FORM_FIELDS_ERROR = 'FORM_FIELDS_ERROR',
}

export interface ParsedError {
  type: ParsedRestErrorType
  code?: string
  status?: number
  payload: string | BackendErrorResponseDetails
}

export const getParsedRestError = ({ t, error }: { t: TFunction; error: Error }) => {
  return (
    getAxiosError(error as AxiosError<BackendErrorResponse | string>, t) ||
    getFirebaseError(error as FirebaseError)
  )
}

export const showErrorNotification = (
  errorData: ParsedError | undefined,
  t: TFunction,
  enqueueSnackbar: EnqueueSnackbar,
) => {
  const message =
    errorData?.type === ParsedRestErrorType.MESSAGE && errorData?.payload
      ? errorData.payload
      : t('actionFailed')
  enqueueSnackbar(message as string, { variant: 'error' })
}

export const handleRestError = (error: Error, t: TFunction, enqueueSnackbar: EnqueueSnackbar) => {
  const errorData = getParsedRestError({ t, error })

  if (errorData?.type === ParsedRestErrorType.FORM_FIELDS_ERROR) {
    return errorData.payload
  }

  showErrorNotification(errorData, t, enqueueSnackbar)
}

const getAxiosError = (error: AxiosError<BackendErrorResponse | string>, t: TFunction) => {
  if (!error.isAxiosError) {
    return
  }

  const serverError = error as AxiosError<BackendErrorResponse>
  const criticalError = error as AxiosError<string>
  let errorData

  if (serverError?.response?.data?.details) {
    errorData = {
      type: ParsedRestErrorType.FORM_FIELDS_ERROR,
      status: serverError.response.status,
      payload: serverError.response.data.details,
    }
  } else if (serverError?.response?.data?.detail) {
    errorData = {
      type: ParsedRestErrorType.MESSAGE,
      status: serverError.response.status,
      payload: serverError.response.data.detail,
    }
  } else if (serverError?.response?.data?.message) {
    errorData = {
      type: ParsedRestErrorType.MESSAGE,
      status: serverError.response.status,
      payload: serverError.response.data.message,
    }
  } else if (criticalError?.response?.data) {
    errorData = {
      type: ParsedRestErrorType.MESSAGE,
      status: criticalError.response.status,
      payload: criticalError.response.data,
    }
  } else if (criticalError?.response) {
    errorData = {
      type: ParsedRestErrorType.ERROR,
      status: criticalError.response.status,
      payload: criticalError.response.data,
    }
  }

  if (errorData && typeof errorData.payload !== 'string') {
    errorData.payload = t('')
  }

  return errorData
}

const getFirebaseError = (error: FirebaseError) => {
  if (error?.name !== 'FirebaseError') {
    return
  }

  return { type: ParsedRestErrorType.MESSAGE, payload: error.message, code: error.code }
}
