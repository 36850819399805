import { captionColor } from '@/setup/theme'

export const radioGroupStyles = {
  'label + &': {
    marginTop: '28px',
  },
}

export const radioStyles = {
  color: captionColor,
  '&.Mui-checked': {
    color: '#EE2F33',
  },
}
