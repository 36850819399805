import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import React, { useState, DragEvent, useEffect, ChangeEvent } from 'react'

import { Close } from '@mui/icons-material'
import { Box, Zoom, Typography, FormControl, FormHelperText } from '@mui/material'

import { useImageSources } from '@/hooks/useImageSources'

import { getProcessedFormErrorInfo } from '@/utils/getProcessedFormErrorInfo'
import {
  maxAllowedMb,
  getUploadedFiles,
  validateAllowedExtensions,
  validateMaxAllowedFileSize,
  checkIfNeedToContinueFilesProcessing,
} from '@/utils/fileUploader'

import Image from '@/components/image/Image'

import { Img, Video } from '../Tags'
import InputLabel from './InputLabel'
import Button, { BUTTON_VARIANTS } from '../Button'
import { FormFieldComponentProps } from '../forms/FormField'
import {
  formHelperTextStyles,
  uploaderButtonStyles,
  uploadFileHintStyles,
  uploaderWrapperStyles,
  uploadedFilesPreviewStyles,
  uploadedFilesItemInfoStyles,
  uploadedFilesItemNameStyles,
  uploadedFileRemoveIconStyles,
  getUploadedFilesWrapperStyles,
  uploadedFilesItemWrapperStyles,
  uploaderActionItemsWrapperStyles,
} from './FileUploader.styles'

export const IMAGE_EXTENSIONS = ['.jpeg', '.jpg', '.png', '.gif']
export const VIDEO_EXTENSIONS = ['.mov', '.mp4']

const getFilePreview = (file: File, imageSources: ReturnType<typeof useImageSources>) => {
  if (file.type.includes('image/')) {
    return <Img src={URL.createObjectURL(file)} sx={uploadedFilesPreviewStyles} />
  }

  if (file.type.includes('video/')) {
    return <Video src={URL.createObjectURL(file)} sx={uploadedFilesPreviewStyles} />
  }

  return (
    imageSources.src && (
      <Image
        alt="other"
        imgSx={uploadedFilesPreviewStyles}
        sources={imageSources.sources}
        src={imageSources.src}
      />
    )
  )
}

const FileUploader: React.FC<FormFieldComponentProps> = ({ meta, ...rest }) => {
  const { t } = useTranslation()
  const containerRef = React.useRef(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const { error, errorMessage } = getProcessedFormErrorInfo(meta, t)

  const [files, setFiles] = useState<File[]>([])
  const isFileLimitReached = typeof rest.filesLimit === 'number' && files.length >= rest.filesLimit
  const handleDragDropEvent = (e: DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const handleDropEvent = (e: DragEvent<HTMLDivElement>) => {
    handleDragDropEvent(e)

    if (rest.disabled || isFileLimitReached) {
      return
    }

    readFiles(e)
  }
  const removeFile = (index: number) => {
    if (index < 0 || index >= files.length) {
      return
    }

    const localFiles = [...files]
    localFiles.splice(index, 1)
    setFiles(localFiles)
    rest.onFilesChange?.(localFiles)
  }
  const clearAllFiles = () => {
    if (!files.length) {
      return
    }

    setFiles([])
  }
  const readFiles = (e: DragEvent | ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = getUploadedFiles(e, isFileLimitReached)

    if (!uploadedFiles) {
      return
    }

    let index = 0
    const newFiles = []
    let continueValidation = true

    do {
      const file = uploadedFiles[index]
      index += 1

      continueValidation = checkIfNeedToContinueFilesProcessing(
        rest.filesLimit,
        files.length,
        newFiles.length,
        uploadedFiles.length,
        index,
      )

      if (!validateAllowedExtensions(rest.allowedExtensions, file.name)) {
        enqueueSnackbar(
          t('uploadedFileUnsupportedFormat', { extensions: rest.allowedExtensions.join(',') }),
          { variant: 'error' },
        )
        break
      }

      if (!validateMaxAllowedFileSize(file.size)) {
        enqueueSnackbar(t('uploadedFileExceedsSizeLimit', { size: maxAllowedMb }), {
          variant: 'error',
        })
        break
      }

      newFiles.push(file)
    } while (continueValidation)

    if (inputRef.current) {
      inputRef.current.value = ''
    }

    if (uploadedFiles.length) {
      const mergedFiles = [...files, ...newFiles]
      setFiles(mergedFiles)
      rest.onFilesChange?.(mergedFiles)
    }
  }

  useEffect(() => {
    if (!meta.dirty) {
      clearAllFiles()
    }
  }, [meta.dirty])

  const imageSources = useImageSources({
    sourceName: 'other_file',
  })

  return (
    <FormControl error={error} fullWidth={rest.fullWidth} ref={containerRef} variant="standard">
      <InputLabel label={rest.label} />
      <Box
        onDragEnter={handleDragDropEvent}
        onDragOver={handleDragDropEvent}
        onDrop={handleDropEvent}
        sx={uploaderWrapperStyles}>
        {!isFileLimitReached && (
          <Box sx={uploaderActionItemsWrapperStyles}>
            <input
              accept={rest.allowedExtensions ? rest.allowedExtensions.join(',') : void 0}
              onChange={readFiles}
              ref={inputRef}
              style={{ display: 'none' }}
              type="file"
            />
            <Button
              disabled={rest.disabled || isFileLimitReached}
              onClick={() => inputRef.current?.click()}
              sx={uploaderButtonStyles}
              variant={BUTTON_VARIANTS.OUTLINED_BTN}>
              {t('chooseFile')}
            </Button>
            <Typography sx={uploadFileHintStyles} variant="body1">
              {t('uploadFileHint')}
            </Typography>
          </Box>
        )}
        {!!files.length && (
          <Box sx={getUploadedFilesWrapperStyles(isFileLimitReached)}>
            {files.map((file, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} sx={uploadedFilesItemWrapperStyles}>
                <Box sx={uploadedFilesItemInfoStyles}>
                  {getFilePreview(file, imageSources)}
                  <Typography sx={uploadedFilesItemNameStyles} variant="body1" noWrap>
                    {file.name}
                  </Typography>
                </Box>
                <Close onClick={() => removeFile(index)} sx={uploadedFileRemoveIconStyles} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Zoom in={error} mountOnEnter unmountOnExit>
        <FormHelperText sx={formHelperTextStyles}>{errorMessage}</FormHelperText>
      </Zoom>
    </FormControl>
  )
}

export default FileUploader
