import { alpha, Theme } from '@mui/material'

export const landingWrapperStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  overflow: 'hidden',
  overflowY: 'auto',
  flexDirection: 'column',
  backgroundColor: 'common.white',
}

export const headerStyles = {
  display: 'flex',
  justifyContent: 'center',
  minHeight: '64px',
  width: '100%',
  borderBottom: '1px solid',
  borderColor: 'common.light',
  background: (theme: Theme) => alpha(theme.palette.common.white, 0.75),
  position: 'sticky',
  top: 0,
  backdropFilter: 'saturate(1.8) blur(20px)',
  zIndex: 3,
}

export const logoStyles = { height: '47px', width: '80px', cursor: 'pointer' }

export const logoWrapperStyles = {
  display: (theme: Theme) => (theme.device.isMobile ? 'none' : 'flex'),
  justifyContent: 'center',
  p: '40px',
}

export const landingContentGroupWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}

export const landingContentWrapperStyles = {
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  minWidth: 200,
  maxWidth: (theme: Theme) => (theme.device.isMobile ? '1248px' : 'calc(100% - (156px * 2))'),
}

export const footerStyles = {
  background: (theme: Theme) => alpha(theme.palette.common.culturedPearl, 0.97),
  backdropFilter: 'blur(10px)',
}
