/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

const BonusSolidIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx, fill: 'inherit', stroke: 'none' }} viewBox="0 0 18 18">
      <path d="M9.27547 3.90109C9.27547 3.90109 7.4829 0.333879 6.67625 0.0530435C5.86959 -0.221817 4.95538 0.602765 4.25628 1.80379C3.56315 2.98688 3.53328 3.50075 4.32798 3.87719C5.12269 4.25961 7.70996 4.86311 8.57637 4.79738C8.85721 4.77945 8.46284 4.41496 8.46284 4.41496C8.46284 4.41496 7.70399 3.97877 6.64637 3.52465C5.59473 3.07054 5.25414 2.91518 5.4334 2.55069C5.61266 2.1862 5.92337 1.85756 6.16835 1.88146C6.53284 1.92329 8.39114 4.34326 8.34334 4.34326C7.87727 4.37314 9.47266 4.60617 9.47266 4.60617L9.27547 3.90109Z" />
      <path d="M8.61125 3.90109C8.61125 3.90109 10.4038 0.333879 11.2105 0.0530435C12.0171 -0.221817 12.9313 0.602765 13.6304 1.80379C14.3236 2.98688 14.3534 3.50075 13.5587 3.87719C12.764 4.25961 10.1768 4.86311 9.31035 4.79738C9.02951 4.77945 9.42388 4.41496 9.42388 4.41496C9.42388 4.41496 10.1827 3.97877 11.2403 3.52465C12.292 3.07054 12.6326 2.91518 12.4533 2.55069C12.2741 2.1862 11.9634 1.85756 11.7184 1.88146C11.3539 1.92329 9.49558 4.34326 9.54338 4.34326C10.0094 4.37314 8.41406 4.60617 8.41406 4.60617L8.61125 3.90109Z" />
      <path d="M8.39334 4.79661C7.9483 4.74262 7.863 3.73828 8.48157 3.35251C8.90932 3.08077 9.47322 3.33803 9.63261 3.87522C9.79255 4.40646 9.49746 4.79875 9.49746 4.79875L8.39334 4.79661Z" />
      <path
        clipRule="evenodd"
        d="M8.24819 4.78516H5.04484C3.09456 4.78516 1.5 6.37679 1.5 8.32347V10.47C2.75236 10.4744 5.39372 10.4816 8.24777 10.477C8.24758 7.98105 8.2476 5.62141 8.24819 4.78516ZM9.72917 10.4735V4.78516H12.6497C14.5999 4.78516 16.2374 6.00949 16.1945 8.32347V10.4659C15.1885 10.4658 11.8357 10.466 9.86699 10.473C9.82105 10.4732 9.77511 10.4733 9.72917 10.4735ZM8.24789 11.9653L1.5 11.9658V14.0656C1.5 16.0123 2.92897 17.5672 5.04484 17.6039H8.24865C8.2485 16.7342 8.24814 14.4165 8.24789 11.9653ZM9.72917 17.6039V11.9652L16.1945 11.9647V14.0656C16.1945 16.0123 14.5999 17.6039 12.6497 17.6039H9.72917Z"
        fillRule="evenodd"
      />
      <path
        d="M14.037 14.867C14.1459 14.867 14.2342 14.7787 14.2342 14.6698C14.2342 14.5609 14.1459 14.4727 14.037 14.4727C13.9281 14.4727 13.8398 14.5609 13.8398 14.6698C13.8398 14.7787 13.9281 14.867 14.037 14.867Z"
        fill="url(#paint0_radial_16229_4042)"
        opacity="0.2"
      />
      <path
        d="M3.75382 14.4725C3.86273 14.4725 3.95101 14.3842 3.95101 14.2753C3.95101 14.1664 3.86273 14.0781 3.75382 14.0781C3.64492 14.0781 3.55664 14.1664 3.55664 14.2753C3.55664 14.3842 3.64492 14.4725 3.75382 14.4725Z"
        fill="url(#paint1_radial_16229_4042)"
        opacity="0.2"
      />
      <path
        d="M13.7852 8.8165C13.9535 8.8165 14.0899 8.68007 14.0899 8.51177C14.0899 8.34347 13.9535 8.20703 13.7852 8.20703C13.6169 8.20703 13.4805 8.34347 13.4805 8.51177C13.4805 8.68007 13.6169 8.8165 13.7852 8.8165Z"
        fill="url(#paint2_radial_16229_4042)"
        opacity="0.37"
      />
      <path
        d="M6.11469 7.04578C6.21039 7.04578 6.28797 6.9682 6.28797 6.8725C6.28797 6.7768 6.21039 6.69922 6.11469 6.69922C6.01899 6.69922 5.94141 6.7768 5.94141 6.8725C5.94141 6.9682 6.01899 7.04578 6.11469 7.04578Z"
        fill="url(#paint3_radial_16229_4042)"
        opacity="0.37"
      />
      <defs>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(21.8195 16.6571) scale(18.1778 18.1777)"
          gradientUnits="userSpaceOnUse"
          id="paint0_radial_16229_4042"
          r="1">
          <stop stopColor="white" stopOpacity="0.48" />
          <stop offset="0.9983" stopColor="currentColor" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(11.5369 16.2596) scale(18.1777 18.1778)"
          gradientUnits="userSpaceOnUse"
          id="paint1_radial_16229_4042"
          r="1">
          <stop stopColor="white" stopOpacity="0.48" />
          <stop offset="0.9983" stopColor="currentColor" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(15.9285 6.08983) scale(12.9124 12.9123)"
          gradientUnits="userSpaceOnUse"
          id="paint2_radial_16229_4042"
          r="1">
          <stop stopColor="white" stopOpacity="0.48" />
          <stop offset="0.9983" stopColor="currentColor" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(7.33632 5.48935) scale(7.36559 7.36562)"
          gradientUnits="userSpaceOnUse"
          id="paint3_radial_16229_4042"
          r="1">
          <stop stopColor="currentColor" stopOpacity="0.48" />
          <stop offset="0.9983" stopColor="currentColor" stopOpacity="0" />
        </radialGradient>
      </defs>
    </SvgIcon>
  )
}

export default BonusSolidIcon
