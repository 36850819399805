import axios from 'axios'

import { handleResponse } from './utils'

export const supportApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_KINJO_SUPPORT_SERVICE_API_KEY
      : '/support/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

supportApi.interceptors.response.use(handleResponse)
