import { useRef, useEffect } from 'react'
import { AxiosError, AxiosInstance } from 'axios'

import { kinjoApi, paymentApi, handleRequestHeaders } from '@/api'

// ----------------------------------------------------------------------

export default function useInterceptors() {
  const kinjoInterceptorId = useRef<number>(-1)
  const paymentInterceptorId = useRef<number>(-1)
  const kinjoErrorInterceptorId = useRef<number>(-1)
  const paymentErrorInterceptorId = useRef<number>(-1)

  useEffect(() => {
    return () => {
      ejectAllInterceptors()
      ejectErrorInterceptors()
    }
  }, [])

  const setErrorInterceptors = (handler: (error: AxiosError) => void) => {
    if (!handler) {
      return
    }

    ejectErrorInterceptors()

    kinjoErrorInterceptorId.current = kinjoApi.interceptors.response.use((res) => res, handler)
    paymentErrorInterceptorId.current = paymentApi.interceptors.response.use((res) => res, handler)
  }

  const ejectErrorInterceptors = () => {
    kinjoErrorInterceptorId.current = ejectResponseInterceptor(
      kinjoApi,
      kinjoErrorInterceptorId.current,
    )
    paymentErrorInterceptorId.current = ejectResponseInterceptor(
      paymentApi,
      paymentErrorInterceptorId.current,
    )
  }

  const ejectResponseInterceptor = (apiInstance: AxiosInstance, interceptorId: number) => {
    interceptorId >= 0 && apiInstance.interceptors.response.eject(interceptorId)
    return -1
  }

  const setInterceptors = (accessToken: string | null) => {
    if (accessToken) {
      ejectAllInterceptors()

      kinjoInterceptorId.current = setInterceptor(kinjoApi, accessToken, true)
      paymentInterceptorId.current = setInterceptor(paymentApi, accessToken, false)
    }
  }

  const setInterceptor = (
    apiInstance: AxiosInstance,
    accessToken: string,
    isBearerToken: boolean,
  ) => apiInstance.interceptors.request.use(handleRequestHeaders(accessToken, isBearerToken))

  const ejectAllInterceptors = () => {
    kinjoInterceptorId.current = ejectRequestInterceptor(kinjoApi, kinjoInterceptorId.current)
    paymentInterceptorId.current = ejectRequestInterceptor(paymentApi, paymentInterceptorId.current)
  }

  const ejectRequestInterceptor = (apiInstance: AxiosInstance, interceptorId: number) => {
    interceptorId >= 0 && apiInstance.interceptors.request.eject(interceptorId)
    return -1
  }

  return { setInterceptors, ejectAllInterceptors, setErrorInterceptors, ejectErrorInterceptors }
}
