import React from 'react'

import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel'
import {
  Theme,
  SxProps,
  FormControlLabel,
  TypographyVariant,
  Checkbox as BaseCheckbox,
} from '@mui/material'

import { mergeSx } from '@/utils/mergeSx'

import { getCheckboxStyles } from '@/components/Checkbox.styles'

import CheckboxIcon from './icons/CheckboxIcon'
import CheckboxCheckedIcon from './icons/CheckboxCheckedIcon'

interface CustomizedCheckboxProps
  extends Omit<FormControlLabelProps, 'control' | 'value' | 'defaultValue' | 'componentsProps'> {
  labelSx?: SxProps<Theme>
  fullWidth?: boolean
  labelVariant?: TypographyVariant
}

const Checkbox = (props: CustomizedCheckboxProps) => {
  const { labelSx, labelVariant, fullWidth = false, ...rest } = props
  return (
    <FormControlLabel
      componentsProps={{ typography: { sx: labelSx, variant: labelVariant } }}
      control={
        <BaseCheckbox
          checkedIcon={<CheckboxCheckedIcon />}
          icon={<CheckboxIcon />}
          sx={{ pt: 0, pb: 0 }}
          disableRipple
        />
      }
      labelPlacement="end"
      {...rest}
      sx={mergeSx(getCheckboxStyles(fullWidth), props.sx)}
    />
  )
}

export default Checkbox
