import { Dispatch, useState, useCallback, SetStateAction } from 'react'

interface UseBooleanOut {
  value: boolean
  setValue: Dispatch<SetStateAction<boolean>>
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
}

export function useBoolean(defaultValue: boolean): UseBooleanOut {
  const [value, setValue] = useState(!!defaultValue)

  const setTrue = useCallback(() => {
    setValue(true)
  }, [])

  const setFalse = useCallback(() => {
    setValue(false)
  }, [])

  const toggle = useCallback(() => {
    setValue((prev) => !prev)
  }, [])

  return {
    value,
    setTrue,
    setFalse,
    toggle,
    setValue,
  }
}
