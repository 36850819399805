import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Zoom,
  Radio,
  FormControl,
  FormHelperText,
  FormControlLabel,
  RadioGroup as RadioGroupMui,
} from '@mui/material'

import { getProcessedFormErrorInfo } from '@/utils/getProcessedFormErrorInfo'

import { radioStyles, radioGroupStyles } from '@/components/inputs/RadioGroup.styles'

import InputLabel from './InputLabel'
import { getFormHelperTextStyles } from './TextInput.styles'
import { FormFieldInputComponentProps } from '../forms/FormField'

type Option = {
  label: string
  value: string
}

interface RadioGroupProps extends FormFieldInputComponentProps {
  options: Option[]
}

const RadioGroup: FC<RadioGroupProps> = ({ input, meta, options, ...rest }) => {
  const { t } = useTranslation()

  const { id, fullWidth } = rest
  const { error, errorMessage } = getProcessedFormErrorInfo(meta, t)

  return (
    <FormControl error={error} fullWidth={fullWidth} variant="standard">
      <InputLabel id={id} label={rest.label} />
      <RadioGroupMui id={id} sx={radioGroupStyles} type="radio" {...input} {...rest}>
        {options.map((option) => (
          <FormControlLabel
            control={<Radio sx={radioStyles} />}
            key={`radioItem_${option.value}`}
            label={option.label}
            value={option.value}
          />
        ))}
      </RadioGroupMui>
      <Zoom in={error} mountOnEnter unmountOnExit>
        <FormHelperText sx={getFormHelperTextStyles()}>{errorMessage}</FormHelperText>
      </Zoom>
    </FormControl>
  )
}

export default RadioGroup
