import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

export const handleRequestHeaders =
  (accessToken: string, isBearerToken: boolean) => (req: InternalAxiosRequestConfig) => {
    if (accessToken) {
      req.headers.Authorization = `${isBearerToken ? 'Bearer ' : ''}${accessToken}`
    }
    return req
  }

export const handleResponse = (response: AxiosResponse) => response.data

export const endpoints = {
  payment: {
    subscriptionPlans: 'subscriptions_plans',
  },
}
