import { useMemo } from 'react'

import { CssBaseline, ThemeProvider } from '@mui/material'

import Router from './routes'
import { createAppTheme } from './setup/theme'
import { useAppContext } from './hooks/useAppContext'

const StyledApp = () => {
  const { deviceInfo } = useAppContext()
  const { isMobile, isLandscape } = deviceInfo
  const theme = useMemo(() => createAppTheme({ isMobile, isLandscape }), [isMobile, isLandscape])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  )
}

export default StyledApp
