import { Theme } from '@mui/material'

export const inputLabelStyles = [
  (theme: Theme) => ({
    ...theme.typography.body1med,
  }),
  {
    display: 'flex',
    whiteSpace: 'pre-wrap',
    transform: 'none',
    '&.Mui-focused': {
      color: 'common.darkBlue',
    },
    '&.Mui-error': {
      color: 'common.newRed',
    },
  },
]
