import { useOutletContext } from 'react-router'

import { Box, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'

import { hideGames } from '@/api/games'
import { useKidFilters } from '@/api/hooks/useKids'

import { useBoolean } from '@/hooks/useBoolean'

import { captionColor } from '@/setup/theme'

import { GameList } from '@/pages/gameReview/GameList'

import Modal from '@/components/modals/Modal'
import Button, { BUTTON_VARIANTS } from '@/components/Button'

import { Caveat } from '@/types/game'

type GameSearchResultsProps = {
  search: string
  contentTagsIds?: Caveat['id'][]
  urlBuilder?: (universeId: string, selectedKid: string | number) => string
  isHiddenGamesOnly?: boolean
}

export const GameSearchResults = ({
  search,
  contentTagsIds,
  urlBuilder,
  isHiddenGamesOnly,
}: GameSearchResultsProps) => {
  const { gamesData, hiddenGameIds, resetCache, kids, isAllGamesHidden, hiddenGamesByKids } =
    useKidFilters(!!isHiddenGamesOnly, search)

  const { selectedKid } =
    useOutletContext<{
      selectedKid: string | number
    }>() || {}

  const kidIndex = kids?.findIndex((kid) => kid.id === Number(selectedKid)) ?? -1
  const hiddenGamesCount = gamesData?.gamePreviews.filter(
    (game) => !hiddenGamesByKids[kidIndex]?.universeIds?.includes(game?.universeId),
  ).length

  const { value: isModalOpen, setFalse: closeModal, setTrue: openModal } = useBoolean(false)
  const { mutateAsync: hideGameMutate } = useMutation({
    mutationFn: hideGames,
    onSuccess: () => {
      resetCache()
    },
  })

  const handleHideClick = () => {
    if (!gamesData) return null
    hideGameMutate({
      kidId: selectedKid,
      universeIds: gamesData?.gamePreviews.map((game) => game.universeId),
    })
    closeModal()
  }

  if (gamesData?.gamePreviews.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          pt: '24px',
          pb: '292px',
          px: '24px',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            mt: '16px',
            maxWidth: '685px',
          }}>
          <Typography variant="title2">No Results Found</Typography>
          <Typography
            color={captionColor}
            mt="12px"
            textAlign="center"
            /* eslint-disable-next-line max-len */
            variant="body1">{`We couldn't find a match for ${contentTagsIds?.length ? 'applied parameters' : `"${search}"`}. Please double-check the
						 spelling or try another search`}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ minHeight: 'calc(100vh - 292px)' }}>
      {!!gamesData?.gamePreviews?.length && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <Typography color={captionColor} sx={{ mb: '8px', mt: '4px' }} variant="body2">
            Found {gamesData?.gamePreviews.length} games
          </Typography>
        </Box>
      )}
      <GameList
        games={gamesData?.gamePreviews || []}
        hiddenGameIds={hiddenGameIds}
        sx={{ flexWrap: 'wrap', p: 0 }}
        urlBuilder={urlBuilder}
      />
      {!isAllGamesHidden && !!kids?.length && selectedKid && (
        <Box
          sx={{
            mt: '16px',
            // sticky button at the bottom
            position: 'sticky',
            bottom: '0',
            p: '24px 16px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'common.white',
            '::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: '-32px',
              right: '-32px',
              borderTop: '1px solid #E4E4E4',
            },
          }}>
          <Button
            onClick={openModal}
            sx={{ maxWidth: '382px', mb: '8px' }}
            variant={BUTTON_VARIANTS.SECONDARY_BTN}
            fullWidth>
            Hide {hiddenGamesCount} games
          </Button>
          <Typography color={captionColor} variant="caption">
            If a game is &quot;hidden&quot;, your child will not see it in the Kinjo app
          </Typography>
        </Box>
      )}
      <Modal
        onModalClose={closeModal}
        open={isModalOpen}
        title={`Hide ${hiddenGamesCount} games?`}
        withCross>
        <Box>
          <Typography component="span" variant="body1bold">
            {kids?.find((kid) => kid.id === selectedKid)?.userName}
          </Typography>{' '}
          <Typography component="span">
            will not see or earn Kinjo coins for hidden games
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: '16px', mt: '32px' }}>
          <Button onClick={closeModal} variant={BUTTON_VARIANTS.OUTLINED_BTN} fullWidth>
            Cancel
          </Button>
          <Button onClick={handleHideClick} fullWidth>
            Hide
          </Button>
        </Box>
      </Modal>
    </Box>
  )
}
