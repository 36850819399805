import { useEffect } from 'react'
import { AxiosError } from 'axios'
import { useParams } from 'react-router'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { useQuery, keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'

import {
  searchGames,
  getGamesReview,
  GAME_REVIEW_KEY,
  getDomainSkills,
  DOMAIN_SKILL_KEY,
  SEARCH_GAME_LIMIT_MAX,
} from '@/api/games'

import { ParsedError, getParsedRestError, showErrorNotification } from '@/utils/handleRestError'

import { DomainSkill, GameReviewType, GamesSearchParams, GamesSearchResponse } from '@/types/game'

export const useDomainSkills = () =>
  useQuery<DomainSkill[]>({
    retry: false,
    staleTime: Infinity,
    queryKey: [DOMAIN_SKILL_KEY],
    queryFn: () => getDomainSkills(),
    select(data) {
      return data.map((domainSkill) => ({
        ...domainSkill,
        randomSeed: Math.floor(Math.random() * 1000),
      }))
    },
  })

/**
 * This hook is used to fetch the game review data and domain skills data.
 * The domain skills data is fetched first and then the game review data is fetched.
 * The domain skills data is then used to remap the domainSkills property of the game review data.
 * The remapped domainSkills property will include the life skills for each domain skill.
 * The hook will return the game review query data which will include the remapped domainSkills property.
 */
export const useGameReview = () => {
  const { t } = useTranslation()
  const { universeId } = useParams()

  /**
   * Fetch the domain skills data.
   * This data is used to remap the domainSkills property of the game review data.
   */
  const domainSkillsQuery = useDomainSkills()

  /**
   * Fetch the game review data.
   * This data is remapped to include the life skills for each domain skill.
   */
  const gameReviewQuery = useQuery<GameReviewType, AxiosError>({
    enabled: !!universeId && !!domainSkillsQuery.data,
    retry: false,
    queryKey: [GAME_REVIEW_KEY, universeId],
    queryFn: () => getGamesReview(universeId),
    select: (data: GameReviewType): GameReviewType => {
      const domainSkills = Object.fromEntries(
        domainSkillsQuery?.data?.map((item) => [item.id, item]) || [],
      )
      return {
        ...data,
        domainSkills: data.domainSkills.map((skill) => {
          const skillData = domainSkills[skill.id] ?? { lifeSkills: [] }
          return {
            ...skill,
            ...skillData,
            lifeSkills: skill.lifeSkills.map((lifeSkill) => {
              const lifeSkillData = skillData?.lifeSkills?.find(
                (skillItem) => skillItem.id === lifeSkill.id,
              )
              return {
                ...lifeSkill,
                ...lifeSkillData,
              }
            }),
          }
        }),
      }
    },
  })

  useEffect(() => {
    if (gameReviewQuery.error) {
      const parsedError: ParsedError | undefined = getParsedRestError({
        t,
        error: gameReviewQuery.error as Error,
      })
      if (parsedError?.status !== 404) {
        showErrorNotification(parsedError, t, enqueueSnackbar)
      }
    }
  }, [gameReviewQuery.error])

  return gameReviewQuery
}

const SEARCH_GAME_KEY = 'SEARCH_GAME_KEY'
const SEARCH_GAME_INFINITE_KEY = 'SEARCH_GAME_INFINITE_KEY'

export const useSearchGame = (data: GamesSearchParams) => {
  const foundGame = useQuery<GamesSearchResponse>({
    enabled: data.filterBy && Object.keys(data.filterBy).some((key) => !!data.filterBy?.[key]),
    retry: false,
    queryKey: [
      SEARCH_GAME_KEY,
      data.offset,
      data.limit,
      data.filterBy?.fuzzyGameName,
      data.filterBy?.universeIds,
      data.filterBy?.caveatSentenceIds,
      data.filterBy?.domainSkillIds,
    ],
    queryFn: () => searchGames(data),
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  })

  return foundGame
}

export const useInfiniteSearchGame = (data: GamesSearchParams) =>
  useInfiniteQuery({
    enabled: data.filterBy && Object.keys(data.filterBy).some((key) => !!data.filterBy?.[key]),
    retry: false,
    queryKey: [
      SEARCH_GAME_INFINITE_KEY,
      data.offset,
      data.limit,
      data.filterBy?.fuzzyGameName,
      data.filterBy?.universeIds,
      data.filterBy?.caveatSentenceIds,
      data.filterBy?.domainSkillIds,
    ],
    queryFn: ({ pageParam }) =>
      searchGames({ ...data, offset: pageParam, sortBy: { newest: true } }),
    staleTime: Infinity,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const nextPageLimit = lastPageParam + SEARCH_GAME_LIMIT_MAX
      return lastPage.totalCount >= nextPageLimit ? nextPageLimit : undefined
    },
    initialPageParam: 0,
  })
