import { useRef, useState, useEffect, useCallback } from 'react'

import { ImageSource } from '@/components/image/Image'

export enum ImageSourceType {
  GENERAL,
  LANDING,
  CARDS,
}

type LoadedImage = {
  src: string
  sources: ImageSource[]
}

const getImageModuleSrc = async (type: ImageSourceType, sourceName: string, extension: string) => {
  switch (type) {
    case ImageSourceType.CARDS:
      return (await import('../../public/img/cards/' + sourceName + '.' + extension)).default
    case ImageSourceType.LANDING:
      return (await import('../../public/img/landing/' + sourceName + '.' + extension)).default
    case ImageSourceType.GENERAL:
    default:
      return (await import('../../public/img/' + sourceName + '.' + extension)).default
  }
}

export const useImageSources = ({
  sourceName,
  sourceType = ImageSourceType.GENERAL,
  defaultExtension = 'svg',
  additionalExtensions,
}: {
  sourceName: string | string[]
  sourceType?: ImageSourceType
  defaultExtension?: string
  additionalExtensions?: string[]
}) => {
  const isLoading = useRef<boolean>(false)
  const [src, setSrc] = useState('')
  const [sources, setSources] = useState<ImageSource[]>([])
  const [images, setImages] = useState<LoadedImage[]>([])

  const load = useCallback(async (targetSourceName: string) => {
    const newSources: ImageSource[] = []

    const newSrc = await getImageModuleSrc(sourceType, targetSourceName, defaultExtension)

    if (additionalExtensions?.length) {
      for await (const extension of additionalExtensions) {
        newSources.push({
          type: `image/${extension}`,
          srcSet: await getImageModuleSrc(sourceType, targetSourceName, extension),
        })
      }
      newSources.push({
        type: `image/${defaultExtension}`,
        srcSet: newSrc,
      })
    }

    return { newSrc, newSources }
  }, [])

  useEffect(() => {
    if (!sourceName || Array.isArray(sourceName)) {
      setSrc('')
      setImages([])
      setSources([])
      return
    }

    if (isLoading.current) {
      return
    }

    const loadSrc = async () => {
      try {
        isLoading.current = true
        const { newSrc, newSources } = await load(sourceName)

        if (newSrc !== src) {
          setSrc(newSrc)
        }

        setSources(newSources)
        setImages([])
      } catch (e) {
        console.warn('useImageSources image module load error', e)
      } finally {
        isLoading.current = false
      }
    }

    loadSrc()
  }, [sourceType, sourceName, defaultExtension, additionalExtensions])

  useEffect(() => {
    if (!sourceName || !Array.isArray(sourceName)) {
      setSrc('')
      setImages([])
      setSources([])
      return
    }

    if (isLoading.current) {
      return
    }

    const loadMultipleSrc = async () => {
      try {
        isLoading.current = true
        const loadedImages: LoadedImage[] = []

        for await (const targetSourceName of sourceName) {
          const { newSrc, newSources } = await load(targetSourceName)
          loadedImages.push({ src: newSrc, sources: newSources })
        }

        setImages(loadedImages)

        setSrc('')
        setSources([])
      } catch (e) {
        console.warn('useImageSources image module load error', e)
      } finally {
        isLoading.current = false
      }
    }

    loadMultipleSrc()
  }, [sourceType, sourceName, defaultExtension, additionalExtensions])

  return {
    src,
    images,
    sources,
  }
}
