/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

const KinjoSolidIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx, fill: 'inherit', stroke: 'none' }} viewBox="0 0 18 18">
      <path d="M4.91216 4.56652C6.17317 4.56652 7.19543 3.54427 7.19543 2.28326C7.19543 1.02225 6.17317 0 4.91216 0C3.65115 0 2.62891 1.02225 2.62891 2.28326C2.62891 3.54427 3.65115 4.56652 4.91216 4.56652Z" />
      <path d="M13.0997 4.56652C11.8439 4.56652 10.8164 3.53906 10.8164 2.28326C10.8164 1.02747 11.8439 0 13.0997 0C14.3555 0 15.3829 1.02747 15.3829 2.28326C15.3829 3.53906 14.3555 4.56652 13.0997 4.56652Z" />
      <path d="M11.0446 5.53125V11.8673C11.0446 12.9845 10.1476 13.8896 9.02233 13.9141C9.00602 13.9141 8.99786 13.9141 8.98155 13.9141C7.84808 13.9141 6.92662 13.0008 6.92662 11.8673V5.53125H2.80859V11.8673C2.80859 15.2514 5.57297 17.9995 8.98155 17.9995C8.99786 17.9995 9.00602 17.9995 9.02233 17.9995C12.4146 17.975 15.1627 15.2433 15.1627 11.8673V5.53125H11.0446Z" />
    </SvgIcon>
  )
}

export default KinjoSolidIcon
