import { gamesApi } from '@/api/gamesApi'
import { kinjoApi } from '@/api/kinjoApi'

import { GamesSearchParams } from '@/types/game'

export const GAME_REVIEW_KEY = 'GAME_REVIEW_KEY'
export const getGamesReview = (universeId?: number | string) =>
  gamesApi.get(`/games/${universeId}?thumbnailSize=_256x144`)

export const DOMAIN_SKILL_KEY = 'DOMAIN_SKILL_KEY'
export const getDomainSkills = () => gamesApi.get('/domain-skills')

export const SEARCH_GAME_LIMIT = 7
export const SEARCH_GAME_LIMIT_MAX = 50
export const searchGames = (data: GamesSearchParams) =>
  gamesApi.post('/games/search', {
    limit: SEARCH_GAME_LIMIT,
    ...data,
  })

export const CAVEATS_KEY = 'CAVEATS_KEY'
export const getCaveats = () => gamesApi.get('/caveat-sentences')

export const HIDDEN_GAMES_KEY = 'HIDDEN_GAMES_KEY'
export const getHiddenGames = (kidId: number | string) =>
  kinjoApi.get(`/games-management/kids/${kidId}/game-catalogs/HIDDEN/games`)

export const hideGames = ({
  kidId,
  universeIds,
}: {
  kidId: string | number
  universeIds: string[]
}) => {
  return kinjoApi.patch(`/games-management/kids/${kidId}/game-catalogs/HIDDEN/games`, {
    universeIds,
  })
}

export const showGame = ({ kidId, universeId }: { kidId: string; universeId: string }) => {
  return kinjoApi.delete(`/games-management/kids/${kidId}/game-catalogs/HIDDEN/games/${universeId}`)
}

export const showGames = ({
  kidId,
  universeIds,
}: {
  kidId: string | number
  universeIds: string[]
}) => {
  return kinjoApi.post(`/games-management/kids/${kidId}/game-catalogs/HIDDEN/games/batch-delete`, {
    universeIds,
  })
}
