import { useCallback } from 'react'

import { useResizeObserver } from './useResizeObserver'

export const useControlledPageDimensions = () => {
  const resizeHandler = useCallback(() => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [window.innerHeight])
  useResizeObserver({ handler: resizeHandler })
}
