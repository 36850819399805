import { useState } from 'react'

import { Box } from '@mui/material'

import { useDomainSkills } from '@/api/hooks/useGameReview'

import { useImageSources } from '@/hooks/useImageSources'

import { GameDirectory } from '@/pages/gameReview/GameDirectory'
import { GameSearchResults } from '@/pages/gameReview/GameSearchResults'
import { GameReviewListHeader } from '@/pages/gameReview/GameReviewListHeader'
import { logoStyles, footerStyles, logoWrapperStyles } from '@/pages/landing/Landing.styles'
import {
  gameReviewWrapper,
  gameReviewListContentWrapper,
} from '@/pages/gameReview/GameReviewDetail.styles'

import Image from '@/components/image/Image'
import { Footer } from '@/components/layout/Footer'
import { handleLogoClick } from '@/components/utils/common'

const GameReviewList = () => {
  const logoImageSource = useImageSources({
    sourceName: 'kinjo_logo',
  })
  const { data: domainSkills } = useDomainSkills()
  const [search, setSearch] = useState('')

  const isSearch = !!search

  const renderGames = () => {
    if (isSearch) {
      return <GameSearchResults search={search} />
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', pt: '24px' }}>
        {domainSkills?.map((domainSkill) => (
          <GameDirectory
            domainSkillId={domainSkill.id}
            domainSkillName={domainSkill.name}
            key={domainSkill.id}
          />
        ))}
      </Box>
    )
  }

  return (
    <Box sx={gameReviewWrapper}>
      <Box sx={logoWrapperStyles}>
        {logoImageSource.src && (
          <Image
            alt="kinjo logo"
            imgSx={logoStyles}
            onClick={handleLogoClick}
            sources={logoImageSource.sources}
            src={logoImageSource.src}
          />
        )}
      </Box>
      <Box sx={gameReviewListContentWrapper}>
        <GameReviewListHeader setSearch={setSearch} />
        {renderGames()}
      </Box>
      <Footer sx={{ ...footerStyles, mt: 'auto' }} />
    </Box>
  )
}

export default GameReviewList
