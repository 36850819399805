import axios from 'axios'

import { handleResponse } from './utils'

export const helperApi = axios.create({
  baseURL: process.env.REACT_APP_KINJO_HELPER_SERVICE_API_KEY,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

helperApi.interceptors.response.use(handleResponse)
