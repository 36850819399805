import { Theme } from '@mui/material'

import { ModalProps } from '@/components/modals/Modal'

export const getDialogPaperStyles = (fullScreen: ModalProps['fullScreen']) =>
  fullScreen
    ? {}
    : {
        borderRadius: '16px',
      }

export const modalTitleWrapperStyles = {
  width: '100%',
  display: 'flex',
  pt: '20px',
  justifyContent: 'center',
}

export const modalTitleStyles = {
  textAlign: 'center',
}

export const crossButtonStyles = {
  top: '16px',
  fill: (theme: Theme) => theme.palette.common.gray,
  right: '16px',
  cursor: 'pointer',
  position: 'absolute',
}

export const modalContentStyles = {
  p: '20px 32px 32px 32px',
  display: 'flex',
  flexDirection: 'column',
}
