import MobileDetected from 'mobile-detect'
import { useState, useEffect, useCallback } from 'react'

import { useMediaQuery } from '@mui/material'

import { useResizeObserver } from '@/hooks/useResizeObserver'

export type DeviceInfo = {
  isMobile: boolean
  isLandscape: boolean
  isAndroidOS: boolean
}
export function getBrowserInfo() {
  const isMobileSize = useMediaQuery('(max-width:900px)')
  const isLandscape = useMediaQuery('(orientation: landscape)')
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    isMobile: isMobileSize,
    isLandscape: false,
    isAndroidOS: false,
  })
  const handler = useCallback(() => {
    const md = new MobileDetected(navigator.userAgent)
    const isMobileDevice = !!((md.phone() || md.mobile()) && md.isPhoneSized())
    setDeviceInfo((prevState) => ({
      ...prevState,
      isMobile: isMobileDevice || isMobileSize,
      isAndroidOS: isMobileDevice && md.is('AndroidOS'),
    }))
  }, [navigator?.userAgent, isMobileSize])
  useResizeObserver({ handler })
  useEffect(() => setDeviceInfo((prevState) => ({ ...prevState, isLandscape })), [isLandscape])

  return deviceInfo
}
