import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, alpha, Theme, SxProps, Typography } from '@mui/material'

import { useImageSources } from '@/hooks/useImageSources'

import { mergeSx } from '@/utils/mergeSx'

import Image from '@/components/image/Image'

import Link from '../Link'
import { Footer as FooterTag } from '../Tags'
import { handleLogoClick } from '../utils/common'
import {
  linkStyles,
  footerWhite,
  dividerStyles,
  footerMaxWidth,
  footerLogoStyles,
  infoWrapperStyles,
} from './Footer.styles'

interface FooterProps {
  sx?: SxProps<Theme>
}

export const Footer: FC<FooterProps> = ({ sx }) => {
  const { t } = useTranslation()
  const currentYear = new Date().getFullYear()

  const imageSource = useImageSources({
    sourceName: 'black_logo_transparency',
  })

  return (
    <FooterTag sx={mergeSx(footerWhite, sx)}>
      <Box sx={footerMaxWidth}>
        {imageSource.src && (
          <Image
            alt="kinjo logo"
            imgSx={footerLogoStyles}
            onClick={handleLogoClick}
            sources={imageSource.sources}
            src={imageSource.src}
          />
        )}
        <Box sx={infoWrapperStyles}>
          <Typography
            color={(theme) => alpha(theme.palette.common.darkBlue, 0.6)}
            variant="caption">
            © {currentYear} Kinjo, Inc.
          </Typography>
          <Typography
            color={(theme) => alpha(theme.palette.common.darkBlue, 0.6)}
            sx={dividerStyles}
            variant="caption">
            {' '}
            ·{' '}
          </Typography>
          <Link
            href="https://kinjo.com/terms-of-service/"
            rel="noopener noreferrer"
            sx={linkStyles}
            target="_blank"
            underline="always">
            {t('termsOfUse')}
          </Link>
          <Typography
            color={(theme) => alpha(theme.palette.common.darkBlue, 0.6)}
            sx={dividerStyles}
            variant="caption">
            {' '}
            ·{' '}
          </Typography>
          <Link
            href="https://kinjo.com/privacy-policy/"
            rel="noopener noreferrer"
            sx={linkStyles}
            target="_blank"
            underline="always">
            {t('privacyPolicy')}
          </Link>
        </Box>
      </Box>
    </FooterTag>
  )
}
