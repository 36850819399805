import { Box, Theme, SxProps } from '@mui/material'

import { useImageSources } from '@/hooks/useImageSources'

import Image from '@/components/image/Image'

const flamesArray = ['flame1', 'flame2', 'flame3', 'flame4', 'flame5']
interface GameFlamesProps {
  earningLevel?: number
  universeId: string | number
  imgSx?: SxProps<Theme>
  sx?: SxProps<Theme>
}
export const GameFlames = ({
  earningLevel = 0,
  universeId,
  imgSx,
  sx: flamesWrapper,
}: GameFlamesProps) => {
  const colorFlame = useImageSources({
    sourceName: 'flame',
  })
  const grayFlame = useImageSources({
    sourceName: 'gray_flame',
  })

  return (
    <Box key={universeId} sx={{ display: 'flex', alignItems: 'center', ...flamesWrapper }}>
      {flamesArray.map((item, i) =>
        i < earningLevel
          ? colorFlame.src && (
              <Image alt="flame" imgSx={imgSx} key={`${universeId}_${item}`} src={colorFlame.src} />
            )
          : grayFlame.src && (
              <Image
                alt="gray flame"
                imgSx={imgSx}
                key={`${universeId}_${item}`}
                src={grayFlame.src}
              />
            ),
      )}
    </Box>
  )
}
