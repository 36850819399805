import axios from 'axios'

import { handleResponse } from './utils'

export const robloxApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_ROBLOX_HOST_API_KEY : '/roblox',
})

robloxApi.interceptors.response.use(handleResponse)
