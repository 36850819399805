import { FC, SyntheticEvent } from 'react'

import { Theme, SxProps } from '@mui/material'

import { mergeSx } from '@/utils/mergeSx'

import { Img, Picture } from '@/components/Tags'

export type ImageSource = {
  type: string
  srcSet: string
}

type ImageProps = {
  alt: string
  sx?: SxProps<Theme>
  src: string
  imgSx?: SxProps<Theme>
  sources?: ImageSource[]
  onClick?: (event: SyntheticEvent) => void
}
const Image: FC<ImageProps> = ({ sx, imgSx, alt, src, sources, onClick }) => {
  return sources?.length ? (
    <Picture sx={mergeSx({ display: 'flex' }, sx)}>
      {sources.map((source) => (
        <source key={source.srcSet} srcSet={source.srcSet} type={source.type} />
      ))}
      <Img alt={alt} onClick={onClick} src={src} sx={imgSx} />
    </Picture>
  ) : (
    <Img alt={alt} onClick={onClick} src={src} sx={imgSx} />
  )
}

export default Image
