import { useOutletContext } from 'react-router'
import { Dispatch, useEffect, SetStateAction } from 'react'

import { useQuery, useQueries } from '@tanstack/react-query'

import { getFamilyMembers } from '@/api/families'
import { useSearchGame } from '@/api/hooks/useGameReview'
import { getHiddenGames, HIDDEN_GAMES_KEY } from '@/api/games'

import { useAuthContext } from '@/hooks/useAuthContext'
import { useLocalStorage } from '@/hooks/useLocalStorage'

import { FAMILY_MEMBERS_KEY } from '@/auth/constants'

import { ContentTag } from '@/pages/gameLibrary/GamesLibrary'

import { FamilyMember } from '@/types/member'

export const useKids = () => {
  const { adult: adultData } = useAuthContext()

  return useQuery<FamilyMember[]>({
    staleTime: Infinity,
    enabled: !!adultData?.familyId,
    retry: false,
    queryKey: [FAMILY_MEMBERS_KEY],
    queryFn: () => getFamilyMembers(adultData?.familyId as number),
  })
}

export const useKidFilters = (isShowOnlyHiddenGames: boolean, searchExternal?: string) => {
  const { adult: adultData } = useAuthContext()

  const {
    selectedKid,
    setSelectedKid,
    appliedContentTags,
    search: searchInternal,
  } = useOutletContext<{
    search: string
    appliedContentTags: ContentTag[]
    selectedKid: string | number
    setSelectedKid: Dispatch<SetStateAction<string | number>>
  }>() || {}
  const search = searchExternal || searchInternal
  const [selectedKidExternal, setSelectedKidExternal] = useLocalStorage('selectedKid', '')

  const { data: kids, isPending: isKidsPending } = useQuery<FamilyMember[]>({
    staleTime: Infinity,
    enabled: !!adultData?.familyId,
    retry: false,
    queryKey: [FAMILY_MEMBERS_KEY],
    queryFn: () => getFamilyMembers(adultData?.familyId as number),
  })

  useEffect(() => {
    if (!kids || !kids.length || selectedKid) return
    if (selectedKidExternal) {
      setSelectedKid?.(Number(selectedKidExternal))
      return
    }
    setSelectedKid?.(kids[0].id)
  }, [kids, setSelectedKid, selectedKidExternal, setSelectedKidExternal])

  const kidIds = kids?.map((kid) => kid.id) ?? []

  const kidQueries = useQueries({
    queries: kidIds?.length
      ? kidIds.map((id) => {
          return {
            queryKey: [HIDDEN_GAMES_KEY, id],
            queryFn: () => getHiddenGames(id),
            staleTime: Infinity,
          }
        })
      : [],
  })

  const hiddenGameIds = [...kidQueries.map((q) => q.data?.universeIds)].flat()
  const selectedKidIndex = kids?.findIndex((kid) => kid.id === selectedKid) ?? -1
  const hiddenGamesIdsByKid =
    kidQueries[selectedKidIndex]?.data?.universeIds === null
      ? []
      : kidQueries[selectedKidIndex]?.data?.universeIds
  const {
    data: gamesData,
    refetch: refetchGames,
    isFetching: isGamesPending,
  } = useSearchGame({
    filterBy: {
      universeIds: isShowOnlyHiddenGames
        ? (selectedKidIndex !== -1 && hiddenGamesIdsByKid) || undefined
        : undefined,
      caveatSentenceIds:
        appliedContentTags?.length && !isShowOnlyHiddenGames && !search
          ? appliedContentTags.map((tag) => tag.id)
          : undefined,
      fuzzyGameName: search ? search : undefined,
    },
    offset: 0,
    limit: 50,
  })

  const resetCache = () => {
    refetchGames()
    kidQueries.forEach((q) => q.refetch())
  }

  return {
    kidsCount: kids?.length ?? 0,
    gamesData,
    hiddenGameIds,
    hiddenGamesByKids: kidQueries.map((q) => q.data),
    kids,
    resetCache,
    isAllGamesHidden: gamesData?.gamePreviews.every(
      (game) => kidQueries[selectedKidIndex]?.data?.universeIds?.includes(game.universeId) || false,
    ),
    isLoading: isGamesPending || kidQueries.some((q) => q.isLoading) || isKidsPending,
  }
}
