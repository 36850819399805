import { Adult, RecentGames, UpdateAdultData } from '@/types/adult'

import { kinjoApi } from './kinjoApi'

export const findAdultByEmail = (email: string) => kinjoApi.post<Adult>('/adults/search', { email })

export const getAdult = () => {
  return kinjoApi.get<Adult>('/adults')
}

export const updateAdult = ({ id, updatedAdult }: UpdateAdultData) => {
  return kinjoApi.patch<Adult>(`/adults/${id}`, updatedAdult)
}

export const newsletterSubscription = (data: { email: string }) => {
  return kinjoApi.post<Adult>('/adults/stay-in-touch', data)
}

export const RECENT_GAMES_KEY = 'RECENT_GAMES_KEY'
export const getRecentGames = (kidId: number | string) =>
  kinjoApi.post<RecentGames>('/kid/game/recent', {
    kidId,
    includeGames: true,
    iconSize: '_50x50',
    offset: 0,
    limit: 6,
  })
