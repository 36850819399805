import { Theme } from '@mui/material'

export const iconNextBtn = [
  {
    position: 'absolute',
    right: 0,
    bottom: '0',
    top: '0',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'fit-content',
    zIndex: 2,
    '& .Mui-disabled': {
      color: 'red',
    },
  },
  (theme: Theme) => ({
    [theme.breakpoints.down(600)]: { right: '12px' },
    [theme.breakpoints.up(600)]: { right: '32px' },
  }),
]

export const iconPrevBtn = [
  {
    position: 'absolute',
    bottom: '0',
    top: '0',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: 'fit-content',
    zIndex: 2,
  },
  (theme: Theme) => ({
    [theme.breakpoints.down(600)]: { left: '12px' },
    [theme.breakpoints.up(600)]: { left: '32px' },
  }),
]

export const getArrowCircleStyles = (isDisabled: boolean, isNext: boolean) => ({
  width: '32px',
  height: '32px',
  rotate: isNext ? '0deg' : '180deg',
  color: isDisabled ? 'common.gray' : 'common.darkBlue',
})

export const getArrowCircleAllGamesStyles = (isDisabled: boolean, isNext: boolean) => ({
  width: '24px',
  height: '24px',
  rotate: isNext ? '0deg' : '180deg',
  color: isDisabled ? 'common.gray' : 'common.darkBlue',
})

export const section4Wrapper = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
  mt: '80px',
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '948px',
}

export const slideStyles = {
  justifyContent: 'center',
  display: 'flex',
  width: '100%',
}

export const swipableViewsStyles = {
  width: '100%',
  position: 'relative',
}
