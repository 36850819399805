import { FC, useMemo } from 'react'

import { Person } from '@mui/icons-material'
import { Box, Theme, SvgIcon, SxProps } from '@mui/material'

import { mergeSx } from '@/utils/mergeSx'

import {
  svgWrapperStyles,
  svgMaskImageStyles,
  userIconImageStyles,
  userIconWrapperStyles,
} from './UserIcon.styles'

export interface UserIconProps {
  sx?: SxProps<Theme>
  iconUrl?: string
  fullName?: string
}

const UserIcon: FC<UserIconProps> = ({ sx, iconUrl, fullName }) => {
  const userIconContent = useMemo(() => {
    if (iconUrl) {
      const ts = Date.now()
      return (
        <SvgIcon sx={svgWrapperStyles}>
          <mask id={`svg-mask-${ts}`}>
            <polygon fill="#ffffff" points="0 0, 24 0, 24 24, 0 24"></polygon>
          </mask>
          <image href={iconUrl} mask={`url(#svg-mask-${ts})`} style={svgMaskImageStyles}></image>
        </SvgIcon>
      )
    } else if (fullName) {
      const letter = fullName.charAt(0).toUpperCase()
      // const letterCoordinates = getCorrectLetterCoordinates(letter)
      return (
        <SvgIcon sx={svgWrapperStyles}>
          <text x={'25%'} y={'75%'}>
            {letter}
          </text>
        </SvgIcon>
      )
    } else {
      return <Person sx={userIconImageStyles} />
    }
  }, [fullName, iconUrl])

  return <Box sx={mergeSx(userIconWrapperStyles, sx)}>{userIconContent}</Box>
}

export default UserIcon
