import { Theme, alpha } from '@mui/material'

export const formHelperTextStyles = [
  (theme: Theme) => ({
    ...theme.typography.caption,
  }),
  {
    top: '70px',
    width: '100%',
    color: 'common.newRed',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
]

export const uploaderWrapperStyles = {
  border: (theme: Theme) => `1px dashed ${theme.palette.common.light}`,
  padding: '12px 16px',
  borderRadius: '8px',
  backgroundColor: 'common.culturedPearl',
  'label + &': {
    marginTop: '28px',
  },
}

export const uploaderActionItemsWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
}

export const uploaderButtonStyles = {
  width: '200px',
}

export const uploadFileHintStyles = {
  color: (theme: Theme) => alpha(theme.palette.common.darkBlue, 0.4),
  paddingLeft: '16px',
}

export const getUploadedFilesWrapperStyles = (isFileLimitReached: boolean) => ({
  display: 'flex',
  maxHeight: '200px',
  overflowY: 'auto',
  marginTop: isFileLimitReached ? 0 : '16px',
  alignItems: 'flex-start',
  flexDirection: 'column',
  '> div:not(:first-of-type)': {
    marginTop: '8px',
  },
})

export const uploadedFilesItemWrapperStyles = {
  width: '100%',
  height: '44px',
  border: (theme: Theme) => `1px solid ${theme.palette.common.light}`,
  padding: '10px 20px',
  display: 'flex',
  maxWidth: '350px',
  borderRadius: '12px',
  justifyContent: 'space-between',
  backgroundColor: 'common.white',
}

export const uploadedFilesItemInfoStyles = {
  display: 'flex',
  maxWidth: 'calc(100% - 24px)',
}

export const uploadedFilesPreviewStyles = {
  width: '24px',
  height: '24px',
}

export const uploadedFilesItemNameStyles = {
  paddingLeft: '12px',
}

export const uploadedFileRemoveIconStyles = {
  color: 'common.gray',
  cursor: 'pointer',
}
