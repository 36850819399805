import { AxiosError } from 'axios'
import { initializeApp } from 'firebase/app'
import { useNavigate } from 'react-router-dom'
import { FC, useMemo, useState, useEffect, ReactNode, useCallback, createContext } from 'react'
import {
  User,
  reload,
  getAuth,
  signOut,
  getIdToken,
  Unsubscribe,
  UserCredential,
  updatePassword,
  signInWithPopup,
  signInAnonymously,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth'

import { useQuery, useQueryClient } from '@tanstack/react-query'

import { getAdult } from '@/api/adult'

import { PATH_AUTH, PATH_ACCOUNT } from '@/routes/paths'

import useActiveLink from '@/hooks/useActiveLink'
import useInterceptors from '@/hooks/useInterceptors'
import { useLocalStorage } from '@/hooks/useLocalStorage'

import { kinjoApi } from '@/api'
import { FIREBASE_API } from '@/config-global'

import { AUTH_VALUE_KEY } from '@/pages/auth/constants'

import { Adult } from '@/types/adult'

import { ADULT_DATA_KEY } from './constants'
import { appleProvider, googleProvider, facebookProvider } from './authProviders'

type AuthProviderProps = {
  children?: ReactNode
}
type CheckOtpWithEmailProps = {
  email: string
  isLogin: boolean
}
type CheckOtpWithPhoneProps = {
  phone: string
  isLogin: boolean
}

export enum NotificationType {
  email = 'EMAIL',
  sms = 'SMS',
}

type VerifyOTP = {
  source: string
  otpCode: string
  notificationType: NotificationType
}

export type VerifyOtpResponse = {
  customToken: string
}

type CheckEmailResponse = {
  otpEnabled: boolean
}

type CheckPhoneResponse = {
  otpEnabled: boolean
}

export type AuthTempValue = Pick<VerifyOTP, 'source' | 'notificationType'>
// ----------------------------------------------------------------------
interface AuthContextType {
  isInitialized: boolean
  isAuthenticated: boolean
  user: User | null
  adult: Adult | undefined
  method: string
  login: (email: string, password: string) => Promise<UserCredential>
  loginWithGoogle: () => void
  loginWithFacebook: () => void
  loginWithApple: () => void
  logout: () => void
  checkEmail: (values: CheckOtpWithEmailProps) => Promise<CheckEmailResponse>
  checkPhone: (values: CheckOtpWithPhoneProps) => Promise<CheckPhoneResponse>
  verifyOtp: (values: VerifyOTP) => Promise<VerifyOtpResponse>
  loginWithCustomToken: (customToken: string) => Promise<UserCredential>
  updateUserPassword: (newPassword: string) => Promise<void>
  isAnonymousUserEnabled: boolean
  setIsAnonymousUserEnabled: (state: boolean) => void
  refetchAdultData: ReturnType<typeof useQuery<Adult>>['refetch']
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API)

const AUTH = getAuth(firebaseApp)

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate()
  const { isActive } = useActiveLink(PATH_AUTH.root)
  const [, setEnteredValue] = useLocalStorage<AuthTempValue>(AUTH_VALUE_KEY)
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const { setInterceptors, ejectAllInterceptors, setErrorInterceptors, ejectErrorInterceptors } =
    useInterceptors()
  const [isInitialized, setInitialized] = useState(false)
  const [isAnonymousUserEnabled, setIsAnonymousUserEnabled] = useState(false)
  const queryClient = useQueryClient()

  const { data: adult, refetch: refetchAdultData } = useQuery<Adult, AxiosError>({
    gcTime: 0,
    enabled: Boolean(user && !user.isAnonymous && !isActive),
    queryKey: [ADULT_DATA_KEY],
    queryFn: getAdult,
  })

  useEffect(() => {
    if (adult) {
      setAuthenticated(true)
    }
  }, [adult])

  useQuery({
    enabled: Boolean(isAnonymousUserEnabled && !user && isInitialized),
    queryKey: ['anonLogin'],
    retry: false,
    queryFn: () => signInAnonymously(AUTH),
  })

  useEffect(() => {
    let unsubscribe: Unsubscribe

    const handleAuthStateChange = async (user: User | null) => {
      if (user) {
        const token = await user.getIdToken()
        setInterceptors(token)
        setUser(user)

        const handleGlobalAuthError = async (error: AxiosError) => {
          if (error?.response?.status === 401) {
            if (!user) {
              setAuthenticated(false)
              await logout()
              return
            }

            const newToken = await user.getIdToken(true)
            setInterceptors(newToken)
            await queryClient.resetQueries()
          }

          return Promise.reject(error)
        }
        setErrorInterceptors(handleGlobalAuthError)
      } else {
        ejectAllInterceptors()
        ejectErrorInterceptors()
        setAuthenticated(false)
        setUser(null)
      }
      setInitialized(true)
    }

    try {
      unsubscribe = onAuthStateChanged(AUTH, handleAuthStateChange)
    } catch (error) {
      console.error(error)
    }

    return () => {
      unsubscribe?.()
      ejectAllInterceptors()
      ejectErrorInterceptors()
    }
  }, [])

  // LOGIN
  const login = useCallback(
    (email: string, password: string) => signInWithEmailAndPassword(AUTH, email, password),
    [],
  )

  const loginWithCustomToken = useCallback(
    (customToken: string) => signInWithCustomToken(AUTH, customToken),
    [],
  )

  const checkEmail = useCallback(({ isLogin, email }: CheckOtpWithEmailProps) => {
    setEnteredValue({ notificationType: NotificationType.email, source: email })
    return kinjoApi.post<CheckEmailResponse>(`/auth/sign-${isLogin ? 'in' : 'up'}`, {
      actionMethod: NotificationType.email,
      source: email,
    })
  }, [])

  const checkPhone = useCallback(({ isLogin, phone }: CheckOtpWithPhoneProps) => {
    setEnteredValue({ notificationType: NotificationType.sms, source: phone })
    return kinjoApi.post<CheckEmailResponse>(`/auth/sign-${isLogin ? 'in' : 'up'}`, {
      actionMethod: NotificationType.sms,
      source: phone,
    })
  }, [])

  const verifyOtp = useCallback(
    (entity: VerifyOTP) => kinjoApi.post<VerifyOtpResponse>('/auth/verify', entity),
    [],
  )

  const handleIntegrationResult = async ({ user }: UserCredential) => {
    typeof user.email === 'string' &&
      setEnteredValue({ notificationType: NotificationType.email, source: user.email })
    try {
      const adultResponse = await refetchAdultData()
      if (adultResponse.data) {
        navigate(PATH_ACCOUNT.family)
        return
      }
      navigate(PATH_AUTH.registerInformation)
    } catch (error) {
      console.error(error)
    }
  }

  const loginWithGoogle = useCallback(() => {
    signInWithPopup(AUTH, googleProvider)
      .then(handleIntegrationResult)
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const loginWithApple = useCallback(() => {
    signInWithPopup(AUTH, appleProvider)
      .then(handleIntegrationResult)
      .catch((error) => {
        console.error(error)
      })
  }, [])

  const loginWithFacebook = useCallback(() => {
    signInWithPopup(AUTH, facebookProvider)
      .then(handleIntegrationResult)
      .catch((error) => {
        console.error(error)
      })
  }, [])

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH)
    queryClient.removeQueries({ queryKey: [ADULT_DATA_KEY] })
    queryClient.clear()
    setUser(null)
    ejectAllInterceptors()
    ejectErrorInterceptors()
  }, [])

  const updateUserPassword = useCallback(
    async (newPassword: string) => {
      await getIdToken(user as User, true)
      await reload(user as User)
      await updatePassword(user as User, newPassword)
    },
    [user],
  )

  const memoizedValue = useMemo(
    () => ({
      isInitialized,
      isAuthenticated,
      user,
      adult,
      method: 'firebase',
      login,
      loginWithGoogle,
      loginWithApple,
      loginWithFacebook,
      logout,
      checkEmail,
      checkPhone,
      verifyOtp,
      loginWithCustomToken,
      updateUserPassword,
      isAnonymousUserEnabled,
      setIsAnonymousUserEnabled,
      refetchAdultData,
    }),
    [
      isAuthenticated,
      isInitialized,
      user,
      adult,
      login,
      loginWithApple,
      loginWithGoogle,
      loginWithFacebook,
      logout,
      checkEmail,
      checkPhone,
      verifyOtp,
      loginWithCustomToken,
      updateUserPassword,
      isAnonymousUserEnabled,
      setIsAnonymousUserEnabled,
      refetchAdultData,
    ],
  )

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}
