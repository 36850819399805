export type ProvideParentalConsent = {
  parentEmail: string
  kidReferralCode: string
}

enum Pronoun {
  HE_HIM_HIS = 'HE_HIM_HIS',
  SHE_HER_HERS = 'SHE_HER_HERS',
  THEY_THEM_THEIRS = 'THEY_THEM_THEIRS',
}
export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NEUTRAL = 'NEUTRAL',
}
export enum ParentAgeVerificationStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
}
export enum ConsentStatus {
  NOT_INITIATED = 'NOT_INITIATED',
  INITIATED = 'INITIATED',
  ACCEPTED = 'ACCEPTED',
}

export interface Member {
  description: string
  id: number
  firstName: string
  lastName: string
  userName: string
  phone: string
  phoneVerified: boolean
  email: string
  emailVerified: boolean
  birthDate: string
  createdDate: string
  pronoun: Pronoun
  gender: Gender
  coinsBonus: number
  referralCode: string
  referralApplied: boolean
  userNameModerated: boolean
  familyId: number
  parentAgeVerificationStatus: ParentAgeVerificationStatus
  consentStatus: ConsentStatus
  robloxUserName: string
}

export type FamilyMember = Pick<
  Member,
  | 'id'
  | 'gender'
  | 'userName'
  | 'lastName'
  | 'firstName'
  | 'birthDate'
  | 'referralCode'
  | 'robloxUserName'
> & { ageProvidedByKid?: number }
