import { Navigate, useRoutes } from 'react-router-dom'

import GameReviewList from '@/pages/gameReview/GameReviewList'
// import { NewPaymentMethod } from '@/pages/account/paymentMethods/newPaymentMethod/NewPaymentMethod'

import { PATH_ACCOUNT } from './paths'
import GuestGuard from '../setup/GuestGuard'
import FamilyPage from '../pages/account/family/FamilyPage'
// import PaymentMethodsPage from '../pages/account/paymentMethods/PaymentMethodsPage'
import {
  OtpCode,
  Landing,
  NotFound,
  LoginPage,
  AuthGuard,
  PageLayout,
  AuthWrapper,
  // RegisterPage,
  ForgotPassword,
  GameLibraryPage,
  TempUnavailable,
  GameReviewDetail,
  AccountPageLayout,
  AccountFamilyPage,
  SubscriptionsPage,
  GameLibraryLayout,
  AccountDetailsPage,
  // RegisterInformation,
  HiddenGamesLibrary,
  // AccountSecurityPage,
  VerificationSuccess,
  VerificationConnect,
  AccountAddChildPage,
  AccountEditChildPage,
  AllGamesByDomainSkill,
  VerificationPageLayout,
  VerificationSubscribed,
  // AccountPaymentMethodsPage,
  AccountContactSupportPage,
  VerificationSuccessVerified,
  VerificationSubscriptionFail,
  // AccountSuggestImprovementPage,
} from './elements'

export default function Router() {
  return useRoutes([
    { path: '', element: <Navigate to="insights" replace />, index: true },
    // Auth
    {
      element: <GuestGuard />,
      children: [
        {
          path: 'auth',
          element: <AuthWrapper />,
          children: [
            { path: '', element: <Navigate to="login" replace /> },
            {
              path: 'forgot-password',
              element: <ForgotPassword />,
            },
            {
              path: 'login',
              children: [
                {
                  element: <LoginPage />,
                  index: true,
                },
                {
                  path: 'otp',
                  element: <OtpCode />,
                },
              ],
            },
            // {
            //   path: 'register',
            //   children: [
            //     {
            //       element: <RegisterPage />,
            //       index: true,
            //     },
            //     {
            //       path: 'otp',
            //       element: <OtpCode />,
            //     },
            //     {
            //       path: 'information',
            //       element: <RegisterInformation />,
            //     },
            //   ],
            // },
          ],
        },
      ],
    },
    // Game library
    {
      path: 'game-library',
      element: <AuthGuard />,
      children: [
        {
          element: <PageLayout />,
          children: [
            {
              path: '',
              element: <GameLibraryLayout />,
              children: [
                { path: '', element: <GameLibraryPage /> },
                { path: 'hidden', element: <HiddenGamesLibrary /> },
              ],
            },
            { path: 'detail/:universeId/:selectedKid', element: <GameReviewDetail /> },
            { path: 'skill/:domainSkillId/:selectedKid', element: <AllGamesByDomainSkill /> },
          ],
        },
      ],
    },
    // Account
    {
      path: 'account',
      element: <AuthGuard />,
      children: [
        {
          element: <PageLayout />,
          children: [
            {
              element: <AccountPageLayout />,
              children: [
                { path: '', element: <Navigate to="details" replace /> },
                { path: 'details', element: <AccountDetailsPage /> },
                // { path: 'security', element: <AccountSecurityPage /> },
                // {
                //   path: 'payment_methods',
                //   element: <PaymentMethodsPage />,
                //   children: [
                //     {
                //       index: true,
                //       element: <AccountPaymentMethodsPage />,
                //     },
                //     {
                //       path: 'new',
                //       element: <NewPaymentMethod />,
                //     },
                //   ],
                // },
                { path: 'subscriptions', element: <SubscriptionsPage /> },
                {
                  path: 'family',
                  element: <FamilyPage />,
                  children: [
                    { path: '*', element: <Navigate to={PATH_ACCOUNT.family} replace /> },
                    { index: true, element: <AccountFamilyPage /> },
                    { path: 'add', element: <AccountAddChildPage /> },
                    { path: 'edit/:memberId', element: <AccountEditChildPage /> },
                  ],
                },
                { path: 'contact_support', element: <AccountContactSupportPage /> },
                // { path: 'suggest_improvement', element: <AccountSuggestImprovementPage /> },
              ],
            },
          ],
        },
      ],
    },
    // Parent verification
    {
      path: 'verification',
      element: <VerificationPageLayout />,
      children: [
        { path: '', element: <Navigate to="intro" replace /> },
        // { path: 'intro', element: <VerificationIntro />, index: true },
        // { path: 'parent', element: <VerificationParent /> },
        // { path: 'card_confirmation', element: <CardConfirmation /> },

        { path: 'success', element: <VerificationSuccess /> },
        { path: 'verified', element: <VerificationSuccessVerified /> },
        // insights extra steps
        { path: 'connect', element: <VerificationConnect /> },
        { path: 'subscribed', element: <VerificationSubscribed /> },
        // TODO remove if stripe reinit works
        { path: 'subscription_fail', element: <VerificationSubscriptionFail /> },
      ],
    },
    {
      path: 'verification/intro',
      element: <TempUnavailable />,
    },
    {
      path: 'verification/parent',
      element: <TempUnavailable />,
    },
    {
      path: 'verification/card_confirmation',
      element: <TempUnavailable />,
    },
    //Landing
    {
      path: 'insights',
      element: <Landing />,
    },
    //Landing Top Games
    {
      path: 'roblox-game-reviews',
      children: [
        { path: '', element: <GameReviewList />, index: true },
        { path: ':universeId', element: <GameReviewDetail isLandingView /> },
      ],
    },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
