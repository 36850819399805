import { alpha, Theme } from '@mui/material'

export const userIconWrapperStyles = {
  width: 'auto',
  height: '100%',
  display: 'flex',
  border: (theme: Theme) => `1px solid ${alpha(theme.palette.common.white, 0.5)}`,
  borderRadius: '50%',
}

export const userIconImageStyles = {
  width: 'inherit',
  height: 'inherit',
  borderRadius: 'inherit',
}

export const svgWrapperStyles = {
  width: 'inherit',
  height: 'inherit',
  fontSize: '17px',
  borderRadius: '50%',
}

export const svgMaskImageStyles = {
  width: '100%',
  height: '100%',
}
