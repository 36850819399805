import { alpha, Theme } from '@mui/material'

import { inputBorderWidth, yInputPaddingWidth } from './TextInput.styles'

export const getDatePickerStyles = ({ height = 44 }: { height?: number }) => [
  {
    '& .MuiInputBase-root': {
      borderRadius: '12px',
      fontWeight: 450,
      fontSize: '16px',
      lineHeight: '24px',
      '&, & .MuiInputBase-input': {
        cursor: 'default',
      },
      '&.Mui-disabled, & .MuiInputBase-input.Mui-disabled': {
        cursor: 'not-allowed',
      },
      'label + &': {
        marginTop: '28px',
      },
      '& > input': {
        height: `${height - inputBorderWidth * 2 - yInputPaddingWidth * 2}px`,
        padding: `${yInputPaddingWidth}px 16px`,
        '&::placeholder': {
          fontSize: '16px',
          fontWeight: 450,
        },
      },
      '& fieldset': {
        all: 'unset',
      },
    },
  },
  (theme: Theme) => ({
    '& .MuiInputBase-root': {
      border: `${inputBorderWidth}px solid ${theme.palette.common.culturedPearl}`,
      transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
      backgroundColor: theme.palette.common.culturedPearl,
      color: theme.palette.common.darkBlue,
      fontFamily: theme.typography.fontFamily,
      '&:hover': {
        borderColor: alpha(theme.palette.common.darkBlue, 0.6),
      },
      '&:focus-within': {
        boxShadow: `${theme.palette.common.darkBlue} 0 0 0 1px`,
        borderColor: theme.palette.common.darkBlue,
        '&.Mui-error': {
          borderColor: theme.palette.common.newRed,
          boxShadow: `${theme.palette.common.newRed} 0 0 0 1px`,
        },
      },
      '&.Mui-error': {
        borderColor: theme.palette.common.newRed,
      },
      '&.Mui-disabled': {
        borderColor: alpha(theme.palette.common.culturedPearl, 0.5),
        backgroundColor: alpha(theme.palette.common.culturedPearl, 0.5),
      },
      '& > input': {
        '&::placeholder': {
          fontFamily: theme.typography.fontFamily,
        },
      },
    },
  }),
]
