import { alpha, Theme } from '@mui/material'

export const footerWhite = {
  width: '100%',
  height: '48px',
  minHeight: '48px',
  display: 'flex',
  justifyContent: 'center',
  background: (theme: Theme) => alpha(theme.palette.common.white, 0.75),
  backdropFilter: 'saturate(1.8) blur(20px)',
  borderTop: '1px solid',
  borderColor: 'common.light',
}

export const infoWrapperStyles = {
  display: 'flex',
}

export const footerLogoStyles = { width: '53px', height: '32px', cursor: 'pointer', opacity: 0.6 }

export const footerMaxWidth = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: 1248, // Maximum width 1200px by design and plus 48px of paddings
  minWidth: 200,
  width: '100%',
  pl: '24px',
  pr: '24px',
}

export const dividerStyles = {
  whiteSpace: 'pre-wrap',
}

export const linkStyles = [
  {
    textDecorationColor: (theme: Theme) => alpha(theme.palette.common.darkBlue, 0.6),
  },
  (theme: Theme) => ({
    ...theme.typography.caption,
    color: alpha(theme.palette.common.darkBlue, 0.6),
  }),
]
