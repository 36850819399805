import { useMemo } from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'

import { PATH_ACCOUNT } from '@/routes/paths'

import useActiveLink from '@/hooks/useActiveLink'
import { useAppContext } from '@/hooks/useAppContext'
import { useAuthContext } from '@/hooks/useAuthContext'

import { getIsParentSubscribed } from '@/utils/getIsParentSubscribed'

import styles from './FamilyPage.module.scss'
import { familyWrapperStyles } from './Family.styles'

const FamilyPage = () => {
  const { deviceInfo } = useAppContext()
  const { adult: adultData } = useAuthContext()
  const { isMobile } = deviceInfo
  const { isActive } = useActiveLink(PATH_ACCOUNT.family, false)
  const wrapperClassName = useMemo(() => {
    if (!adultData) {
      return ''
    }

    if (getIsParentSubscribed(adultData.subscriptionType)) {
      return isActive
        ? isMobile
          ? styles.accountFamilyPlusMobileWrapper
          : styles.accountFamilyPlusWrapper
        : ''
    } else {
      return isActive
        ? isMobile
          ? styles.accountFamilyBasicMobileWrapper
          : styles.accountFamilyBasicWrapper
        : ''
    }
  }, [isActive, isMobile, adultData])

  return (
    <Box className={wrapperClassName} sx={familyWrapperStyles}>
      <Outlet />
    </Box>
  )
}

export default FamilyPage
