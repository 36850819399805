import { Family } from '@/types/family'
import { Member, FamilyMember } from '@/types/member'

import { kinjoApi } from './kinjoApi'

export type ReferralCodes = { referralCode: string; firstName?: string }[]

export const addMemberToFamily = (familyId: number, memberReferralCodes: ReferralCodes) =>
  kinjoApi.post(`/families/${familyId}/members`, {
    addFamilyMemberDtos: memberReferralCodes,
    parentEmailLockPolicy: 'AGE_VERIFICATION',
  })

export const createFamily = (adultId: number, memberReferralCodes: ReferralCodes) =>
  kinjoApi.post<Family>('/families', {
    adultId,
    addFamilyMemberDtos: memberReferralCodes,
    parentEmailLockPolicy: 'AGE_VERIFICATION',
  })

export const getFamilyMembers = (familyId: number) => kinjoApi.get(`/families/${familyId}/members`)

export const getFamilyMemberById = (memberId: FamilyMember['id']) =>
  kinjoApi.get<FamilyMember>(`/member/${memberId}/family-member-details`)

export const removeChildFromFamily = (familyId: Family['id'], memberId: FamilyMember['id']) =>
  kinjoApi.delete<FamilyMember>(`/families/${familyId}/members/${memberId}`)

export type UpdateMemberByParentData = Pick<FamilyMember, 'firstName' | 'birthDate'>
export const updateMemberByParent = (memberId: number, updateDto: UpdateMemberByParentData) =>
  kinjoApi.patch<Partial<Member>>(`member/${memberId}/update-by-parent`, updateDto)
