/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

const DatePickerAdornmentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx, fill: 'none' }}>
      <path
        d="M18.5 4H5.5C3.84315 4 2.5 5.34315 2.5 7V19C2.5 20.6569 3.84315 22 5.5 22H18.5C20.1569 22 21.5 20.6569 21.5 19V7C21.5 5.34315 20.1569 4 18.5 4Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
      <path
        d="M2.5 10L21.5 10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
      <path
        d="M7 7H16.9999"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
      <path
        d="M7 2L7 4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
      <path
        d="M17 2L17 4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </SvgIcon>
  )
}

export default DatePickerAdornmentIcon
