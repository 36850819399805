import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import ReactTiktokPixel from 'tiktok-pixel'
import ReactPixel from 'react-facebook-pixel'
import { BrowserRouter } from 'react-router-dom'
import ReactRedditPixel from 'react-reddit-pixel'
import { closeSnackbar, SnackbarProvider } from 'notistack'

import { Close } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import StyledApp from './StyledApp'
import { AppProvider } from './setup/AppContext'
import { AuthProvider } from './auth/FirebaseContext'
import { useControlledPageDimensions } from './hooks/useControlledPageDimensions'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

const App = () => {
  useControlledPageDimensions()

  useEffect(() => {
    const gtmId = process.env.REACT_APP_INSIGHTS_GTM_ID

    if (!gtmId) {
      return
    }

    const tagManagerArgs = {
      gtmId,
    }
    TagManager.initialize(tagManagerArgs)
  }, [])

  useEffect(() => {
    const pixelId = process.env.REACT_APP_INSIGHTS_PIXEL_ID

    if (!pixelId) {
      return
    }

    ReactPixel.init(pixelId)
    ReactPixel.pageView()
  }, [])

  useEffect(() => {
    const tiktokPixelId = process.env.REACT_APP_INSIGHTS_TIKTOK_PIXEL_ID

    if (!tiktokPixelId) {
      return
    }

    ReactTiktokPixel.init(tiktokPixelId)
    ReactTiktokPixel.pageView()
  }, [])

  useEffect(() => {
    const redditPixelId = process.env.REACT_APP_INSIGHTS_REDDIT_PIXEL_ID

    if (!redditPixelId) {
      return
    }

    ReactRedditPixel.init(redditPixelId)
    ReactRedditPixel.pageVisit()
  }, [])

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider
                action={(snackbarId) => (
                  <Close
                    onClick={() => closeSnackbar(snackbarId)}
                    sx={{ width: '16px', cursor: 'pointer' }}
                  />
                )}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                maxSnack={3}>
                <StyledApp />
              </SnackbarProvider>
            </LocalizationProvider>
          </AppProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
