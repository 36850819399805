import { Theme } from '@mui/material'

import { LINEAR_GRADIENT_LIGHT_GRAY } from '@/setup/theme'

export const childCardWrapperStyles = [
  {
    mb: '24px',
    width: '100%',
    display: 'flex',
    padding: '24px',
    background: LINEAR_GRADIENT_LIGHT_GRAY,
    borderRadius: '16px',
  },
  (theme: Theme) =>
    theme.device.isMobile && !theme.device.isLandscape
      ? { minHeight: '326px', flexDirection: 'column', mb: '16px' }
      : {},
]

export const childCardSeparator = [
  {
    background:
      'linear-gradient(180deg, rgba(225, 227, 255, 0.60) 0%, #E1E3FF 48.44%, rgba(225, 227, 255, 0.60) 100%)',
  },
  (theme: Theme) =>
    theme.device.isMobile && !theme.device.isLandscape
      ? { width: '100%', height: '1px', margin: '23px 0' }
      : { width: '1px', height: '100%', margin: '0 36px' },
]

const childCardInfoWrapperStyles = {
  flex: 0.5,
  display: 'flex',
  flexDirection: 'column',
}

export const childCardLeftInfoWrapperStyles = [
  {
    ...childCardInfoWrapperStyles,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  (theme: Theme) =>
    theme.device.isMobile && !theme.device.isLandscape ? { gap: '24px' } : { gap: '8px' },
]

export const childCardPersonalInfoWrapperStyles = {
  gap: '10px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}

export const childAvatarStyles = {
  width: '60px',
  height: '60px',
}

export const childCardPersonalDataWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}

export const childCardButtonsWrapperStyles = {
  gap: '8px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}

export const childCardRightInfoWrapperStyles = [
  {
    ...childCardInfoWrapperStyles,
    justifyContent: 'space-around',
  },
  (theme: Theme) =>
    theme.device.isMobile && !theme.device.isLandscape
      ? { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }
      : {},
]

export const childDetailsWrapperStyles = [
  {
    display: 'flex',
    alignItems: 'center',
  },
  (theme: Theme) =>
    theme.device.isMobile && !theme.device.isLandscape
      ? {
          width: '50%',
          '&:nth-child(n+3)': {
            pt: '22px',
          },
        }
      : {},
]

export const childDetailsIconWrapperStyles = {
  fill: (theme: Theme) => theme.palette.common.white,
  width: '32px',
  height: '32px',
  stroke: (theme: Theme) => theme.palette.common.white,
  padding: '7px',
  display: 'flex',
  background:
    'linear-gradient(134deg, rgba(157,133,224,0.63) 34.26%, rgba(162,114,227,0.63) 77.72%)',
  alignItems: 'center',
  borderRadius: '50%',
  justifyContent: 'center',
}

export const childDetailsInfoWrapperStyles = {
  pl: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}
