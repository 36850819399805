import { differenceInYears } from 'date-fns'

export const findDaysDifferent = (fromDate: string | number | Date): string => {
  const createdDate = new Date(fromDate)
  const today = new Date()
  let requiredDifferentDays: string

  const oneDay = 24 * 60 * 60 * 1000 // The number of milliseconds in one day
  const diffDays = Math.round(Math.abs((createdDate.getTime() - today.getTime()) / oneDay))

  if (diffDays >= 365) {
    requiredDifferentDays =
      Math.floor(diffDays / 365) === 1 ? '1 year ago' : `${Math.floor(diffDays / 365)} years ago`
  } else if (diffDays >= 30) {
    requiredDifferentDays =
      Math.floor(diffDays / 30) === 1 ? '1 month ago' : `${Math.floor(diffDays / 30)} months ago`
  } else {
    requiredDifferentDays = diffDays === 1 || diffDays === 0 ? '1 day ago' : `${diffDays} days ago`
  }

  return requiredDifferentDays
}

export const getAge = ({
  date,
  ageProvidedByKid,
}: {
  date?: string | Date
  ageProvidedByKid?: number
}) => {
  if (date) {
    const dateObject = date instanceof Date ? date : new Date(date)

    if (isNaN(dateObject.getTime())) {
      return 0
    }

    return differenceInYears(Date.now(), dateObject)
  }

  if (ageProvidedByKid) {
    return ageProvidedByKid
  }
}
