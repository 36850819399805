import { CSSProperties } from 'react'

import { Theme, alpha, createTheme } from '@mui/material'

// @ts-expect-error - no types
import CircularStd from '@/fonts/CircularStd-Book.woff'
// @ts-expect-error - no types
import CircularStdBold from '@/fonts/CircularStd-Bold.woff'
// @ts-expect-error - no types
import CircularStdBlack from '@/fonts/CircularStd-Black.woff'
// @ts-expect-error - no types
import CircularStdMedium from '@/fonts/CircularStd-Medium.woff'
// @ts-expect-error - no types
import CircularStdItalic from '@/fonts/CircularStd-BookItalic.woff'
// @ts-expect-error - no types
import CircularStdBoldItalic from '@/fonts/CircularStd-BoldItalic.woff'
// @ts-expect-error - no types
import CircularStdBlackItalic from '@/fonts/CircularStd-BlackItalic.woff'
// @ts-expect-error - no types
import CircularStdMediumItalic from '@/fonts/CircularStd-MediumItalic.woff'

import { DeviceInfo } from './deviceDetect'

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    device: Pick<DeviceInfo, 'isMobile' | 'isLandscape'>
  }
  interface ThemeOptions {
    device?: Pick<DeviceInfo, 'isMobile' | 'isLandscape'>
  }
}

declare module '@mui/material/styles/createPalette' {
  // TODO: remove old palette and replace it with the new one
  interface CommonColors {
    mediumBlue: string
    darkBlue: string
    gray: string
    lightGray: string
    light: string
    lighter: string
    blue: string
    red: string
    // new colors (tmp)
    newRed: string
    newDarkerBlue: string
    culturedPearl: string
    kinjoLogoRed: string
    kinjoLogoOrange: string
    green: string
    lightBlue: string
    greenLight: string
    ghostWhite: string
    darkSoftViolet: string
    pattensBlue: string
    serenade: string
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    extraLarge?: CSSProperties
    ultraLarge?: CSSProperties
    large?: CSSProperties
    title1: CSSProperties
    title2: CSSProperties
    title3: CSSProperties
    title4: CSSProperties
    body1: CSSProperties
    body1bold: CSSProperties
    body1med: CSSProperties
    body2: CSSProperties
    body2bold: CSSProperties
    caption: CSSProperties
    bodyLarge: CSSProperties
    bodyLargeBold: CSSProperties
  }

  interface TypographyVariantsOptions {
    extraLarge?: CSSProperties
    ultraLarge?: CSSProperties
    large?: CSSProperties
    title1?: CSSProperties
    title2?: CSSProperties
    title3?: CSSProperties
    title4?: CSSProperties
    body1?: CSSProperties
    bodyLarge?: CSSProperties
    bodyLargeBold?: CSSProperties
    body1med?: CSSProperties
    body1bold?: CSSProperties
    body2?: CSSProperties
    body2bold?: CSSProperties
    caption?: CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    extraLarge: true
    ultraLarge: true
    large: true
    title1: true
    title2: true
    title3: true
    title4: true
    body1: true
    bodyLarge: true
    bodyLargeBold: true
    body1med: true
    body1bold: true
    body2: true
    body2bold: true
    caption: true
  }
}

const greenLight = '#E6F6EC'

export const createAppTheme = ({ isMobile = false, isLandscape = false }) =>
  createTheme({
    typography: {
      fontFamily: 'Circular Std',
      allVariants: {
        color: '#17222F',
      },
      extraLarge: {
        fontWeight: 900,
        fontSize: '64px',
        lineHeight: '70px',
      },
      ultraLarge: {
        fontWeight: 900,
        fontSize: '48px',
        lineHeight: '60px',
      },
      large: {
        fontWeight: 900,
        fontSize: '36px',
        lineHeight: '45px',
      },
      title1: {
        fontWeight: 900,
        fontSize: '32px',
        lineHeight: '40px',
      },
      title2: {
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '30px',
      },
      title3: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '25px',
      },
      title4: {
        fontWeight: 450,
        fontSize: '20px',
        lineHeight: '28px',
      },
      body1: {
        fontWeight: 450,
        fontSize: '16px',
        lineHeight: '24px',
      },
      bodyLarge: {
        fontWeight: 450,
        fontSize: '20px',
        lineHeight: '30px',
      },
      bodyLargeBold: {
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '30px',
      },
      body1med: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
      },
      body1bold: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
      },
      body2: {
        fontWeight: 450,
        fontSize: '14px',
        lineHeight: '21px',
      },
      body2bold: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '21px',
      },
      caption: {
        fontWeight: 450,
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
    palette: {
      common: {
        mediumBlue: '#1F466C',
        darkBlue: '#17222F',
        gray: '#8E8E93',
        lightGray: '#E7F0FA',
        light: '#E4E4E4',
        lighter: '#F2F2F2',
        blue: '#0A76D9',
        red: '#D32100',
        // new colors (tmp)
        newRed: '#D42F2F',
        newDarkerBlue: '#0F2133',
        culturedPearl: '#F7F7F7',
        kinjoLogoRed: '#FF005C',
        kinjoLogoOrange: '#FFAE1F',
        green: '#008A03',
        lightBlue: '#D7EBFB',
        greenLight,
        ghostWhite: '#F7F9FF',
        darkSoftViolet: '#534AE0',
        pattensBlue: '#E2E7EB',
        serenade: '#FFE5D3',
      },
    },
    components: {
      MuiDivider: {
        styleOverrides: {
          // Name of the slot
          root: (themeParam) => ({
            color: themeParam.theme.palette.common.gray,
            '&::after': {
              borderColor: themeParam.theme.palette.common.light,
            },
            '&::before': {
              borderColor: themeParam.theme.palette.common.light,
            },
          }),
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          body: {
            background-color: #F1FAFF;
          }
          @font-face {
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 450;
            src: local('Circular Std'), url(${CircularStd}) format('woff');
            font-display: swap;
          }
          @font-face {
            font-family: 'Circular Std';
            font-style: italic;
            font-weight: 450;
            src: local('Circular Std'), url(${CircularStdItalic}) format('woff');
            font-display: swap;
          }
          @font-face {
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 500;
            src: local('Circular Std'), url(${CircularStdMedium}) format('woff');
            font-display: swap;
          }
          @font-face {
            font-family: 'Circular Std';
            font-style: italic;
            font-weight: 500;
            src: local('Circular Std'), url(${CircularStdMediumItalic}) format('woff');
            font-display: swap;
          }
          @font-face {
            font-family: 'Circular Std';
            font-style: normal;
            font-weight: 700;
            src: local('Circular Std'), url(${CircularStdBold}) format('woff');
            font-display: swap;
          }
          @font-face {
            fontFamily: 'Circular Std';
            font-style: italic;
            font-weight: 700;
            src: local('Circular Std'), url(${CircularStdBoldItalic}) format('woff');
            font-display: swap;
          }
          @font-face {
            fontFamily: 'Circular Std';
            font-style: normal;
            font-weight: 900;
            src: local('Circular Std'), url(${CircularStdBlack}) format('woff');
            font-display: swap;
          }
          @font-face {
            font-family: 'Circular Std';
            font-style: italic;
            font-weight: 900;
            src: local('Circular Std'), url(${CircularStdBlackItalic}) format('woff');
            font-display: swap;
          }`,
      },
    },
    device: {
      isMobile,
      isLandscape,
    },
  })

export const LINEAR_GRADIENT_RED = 'linear-gradient(270deg, #E11A4F 0%, #FC4513 101%)'

export const LINEAR_GRADIENT_TEXT_BOX_GREEN = `linear-gradient(${greenLight}, ${greenLight}) padding-box,
 linear-gradient(90deg, #008A0233, #008A03CC) border-box`

export const LINEAR_GRADIENT_LIGHT_GRAY = 'linear-gradient(121deg, #F2F8FF 0%, #F3F1FF 100%)'

export const captionColor = (theme: Theme) => alpha(theme.palette.common.darkBlue, 0.6)

export const redGradientText = {
  background: LINEAR_GRADIENT_RED,
  backgroundClip: 'text',
  textFillColor: 'transparent',
}
