import { forwardRef } from 'react'
import { useParams, useNavigate, useOutletContext } from 'react-router'

import { VisibilityOffOutlined } from '@mui/icons-material'
import { Box, Card, Theme, alpha, SxProps, Typography, CardActionArea } from '@mui/material'

import { useKidFilters } from '@/api/hooks/useKids'

import { PATH_LANDINGS } from '@/routes/paths'

import { ImageSourceType, useImageSources } from '@/hooks/useImageSources'

import { mergeSx } from '@/utils/mergeSx'

import { captionColor } from '@/setup/theme'

import { getChildIconUrl } from '@/pages/account/family/FamilyList/ChildCard'
import {
  gameName,
  gameImage,
  gameFlames,
  gameWrapper,
  gameListWrapper,
  gameDescription,
  gameFlamesImage,
  gameFlamesDivider,
  ageRecommendation,
  ageRecommendationImage,
} from '@/pages/gameReview/GameList.styles'

import Image from '@/components/image/Image'
import UserIcon from '@/components/UserIcon'
import { GameFlames } from '@/components/GameFlames'

import { GamePreview } from '@/types/game'

type GameListProps = {
  games: GamePreview[]
  urlBuilder?: (universeId: string, selectedKid: string | number) => string
  sx?: SxProps<Theme>
  hiddenGameIds?: string[]
}

type Ref = HTMLDivElement

export const GameList = forwardRef<Ref, GameListProps>(
  ({ games, urlBuilder = PATH_LANDINGS.gameReview, sx, hiddenGameIds }, ref) => {
    const navigate = useNavigate()
    const ageImage = useImageSources({
      sourceName: 'topGames1_age',
      sourceType: ImageSourceType.LANDING,
      defaultExtension: 'svg',
    })
    const { hiddenGamesByKids, kids } = useKidFilters(true)

    const { selectedKid: contextSelectedKid } =
      useOutletContext<{
        selectedKid: string | number
      }>() ?? {}

    const { selectedKid: paramsSelectedKid } = useParams()
    const selectedKid = contextSelectedKid ?? paramsSelectedKid

    return (
      <Box sx={mergeSx(gameListWrapper, sx)}>
        {games.map((game) => (
          <Card
            key={game.universeId}
            onClick={() => navigate(urlBuilder(game.universeId, Number(selectedKid)))}
            ref={ref}
            sx={gameWrapper}>
            <CardActionArea>
              {hiddenGameIds?.includes(game.universeId) && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: '8px',
                    right: '7px',
                    gap: '6px',
                    borderRadius: '100px',
                    backgroundColor: (theme: Theme) => alpha(theme.palette.common.white, 0.8),
                    p: '4px',
                    isolation: 'isolate',
                  }}>
                  <VisibilityOffOutlined />
                  {hiddenGamesByKids.reduce((acc, kid, index) => {
                    const { universeIds } = kid
                    const kidsData = kids?.[index]

                    if (!universeIds || !universeIds.includes(game.universeId)) return acc

                    acc.push(
                      <UserIcon
                        fullName={kidsData?.firstName}
                        iconUrl={getChildIconUrl(kidsData?.gender)}
                        key={kidsData?.id}
                        sx={{
                          width: '36px',
                          height: '36px',
                          backgroundColor: 'common.white',
                          marginLeft: `-${acc.length * 20}px`,
                        }}
                      />,
                    )
                    return acc
                  }, [])}
                </Box>
              )}
              <Box sx={gameImage}>
                <Image
                  alt={`game image ${game.imageUrl}`}
                  imgSx={{ height: '100%' }}
                  src={game.imageUrl}
                />
              </Box>
              <Box sx={gameDescription}>
                <Typography sx={gameName} variant="body1med">
                  {game.name}
                </Typography>
                <Box sx={gameFlames}>
                  <GameFlames
                    earningLevel={game.earningLevel}
                    imgSx={gameFlamesImage}
                    universeId={game.universeId}
                  />{' '}
                  <Box sx={gameFlamesDivider} />
                  <Box sx={ageRecommendation}>
                    {ageImage.src && (
                      <Image
                        alt="age logo"
                        imgSx={ageRecommendationImage}
                        key={ageImage.src}
                        sources={ageImage.sources}
                        src={ageImage.src}
                      />
                    )}
                    <Typography color={captionColor} variant="body1med">
                      {game?.ageRecommendation}+
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    )
  },
)

GameList.displayName = 'GameList'
