/* eslint-disable max-len */
import { Theme, SvgIcon, SvgIconProps } from '@mui/material'

const CheckboxCheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ fill: (theme: Theme) => theme.palette.common.blue, ...props.sx }}>
      <rect height="20" rx="4.8" width="20" x="2" y="2" />
      <path
        clipRule="evenodd"
        d="M16.545 9.84892L12.2959 14.3043L10.8795 15.7896L9.46316 14.3043L9.47102 14.2961L7.4541 12.1582L8.87085 10.673L10.8874 12.8112L15.1286 8.36328L16.545 9.84892Z"
        fill="white"
        fillRule="evenodd"
      />
    </SvgIcon>
  )
}

export default CheckboxCheckedIcon
