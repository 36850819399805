import { forwardRef } from 'react'

import { LinkProps, Link as BaseLink } from '@mui/material'

import { mergeSx } from '@/utils/mergeSx'

const linkStyles = {
  cursor: 'pointer',
  color: 'common.blue',
  ':disabled': {
    opacity: 0.4,
    pointerEvents: 'none',
  },
}

type Ref = HTMLAnchorElement
type CustomizedLinkProps = LinkProps & { disabled?: boolean }

const Link = forwardRef<Ref, CustomizedLinkProps>((props, ref) => (
  <BaseLink
    aria-disabled={props.disabled}
    ref={ref}
    tabIndex={props.disabled ? -1 : void 0}
    underline="hover"
    {...props}
    sx={mergeSx(linkStyles, props.sx)}>
    {props.children}
  </BaseLink>
))

Link.displayName = 'Link'

export default Link
