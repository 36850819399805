import React, { FC, ReactNode } from 'react'

import { Close } from '@mui/icons-material'
import {
  Box,
  Theme,
  Dialog,
  SxProps,
  Typography,
  Breakpoint,
  DialogTitle,
  DialogContent,
  ModalProps as BaseModalProps,
} from '@mui/material'

import { mergeSx } from '@/utils/mergeSx'

import {
  modalTitleStyles,
  crossButtonStyles,
  modalContentStyles,
  getDialogPaperStyles,
  modalTitleWrapperStyles,
} from './Modal.styles'

export interface ModalProps extends Pick<BaseModalProps, 'open'> {
  onModalClose?: () => void
  setOpen?: (state: boolean) => void
  children?: ReactNode | ReactNode[]
  withCross?: boolean
  fullScreen?: boolean
  stylesOverrides?: {
    paperSx?: SxProps<Theme>
    titleSx?: SxProps<Theme>
    contentSx?: SxProps<Theme>
  }
  maxWidth?: false | Breakpoint | undefined
  title?: ReactNode
}

const Modal: FC<ModalProps> = ({
  open,
  title,
  children,
  onModalClose,
  setOpen,
  withCross,
  stylesOverrides,
  fullScreen,
  maxWidth,
}) => {
  const handleClose = () => {
    onModalClose?.()
    setOpen?.(false)
  }

  return (
    <Dialog
      PaperProps={{
        sx: mergeSx(getDialogPaperStyles(fullScreen), stylesOverrides?.paperSx),
      }}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
      scroll="paper">
      <DialogTitle sx={{ p: '12px' }}>
        {title && (
          <Box sx={modalTitleWrapperStyles}>
            <Typography sx={mergeSx(modalTitleStyles, stylesOverrides?.titleSx)} variant="title3">
              {title}
            </Typography>
          </Box>
        )}
      </DialogTitle>
      {withCross && <Close onClick={handleClose} sx={crossButtonStyles} />}
      <DialogContent sx={mergeSx(modalContentStyles, stylesOverrides?.contentSx)}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default Modal
