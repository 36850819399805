import { useSearchParams } from 'react-router-dom'
import { FC, useMemo, useState, ReactNode, useEffect, useCallback, createContext } from 'react'

import { useQuery } from '@tanstack/react-query'

import { SubscriptionPlan } from '@/api/payment'
import { getMemberByReferralCode } from '@/api/member'

import { useAuthContext } from '@/hooks/useAuthContext'

import { PaymentMethod } from '@/types/paymentMethod'
import { ConsentStatus, ParentAgeVerificationStatus } from '@/types/member'

import { DeviceInfo, getBrowserInfo } from './deviceDetect'

export interface VerificationData {
  email: string
  username: string
  memberId: string
  agreed: boolean
  referralCode: string
  subscribedOnUpdates: boolean
  verificationStarted: boolean
  isInitialized: boolean
  isConsentAccepted: boolean
  isParentAgeVerified: boolean
  isStepperVisible: boolean
  // insights extra fields
  subscribed: boolean
  subscriptionFailed: boolean
}

interface OtpData {
  otp: string
  error: boolean
}
export interface StripeData {
  setupIntentId: string
}
export interface BurgerMenuData {
  isOpened: boolean
}

type UpdateVerificationDataPayload = Partial<
  Omit<
    VerificationData,
    'email' | 'referralCode' | 'isConsentAccepted' | 'isParentAgeVerified' | 'username'
  >
>

// ----------------------------------------------------------------------

type AppProviderProps = {
  children?: ReactNode
}

interface AppContextType {
  otpData: OtpData
  deviceInfo: DeviceInfo
  stripeData: StripeData
  updateOtpData: (data: Partial<OtpData>) => void
  burgerMenuData: BurgerMenuData
  verificationData: VerificationData
  updateStripeData: (data: Partial<StripeData>) => void
  updateBurgerMenuData: (data: Partial<BurgerMenuData>) => void
  updateVerificationData: (data: Partial<VerificationData>) => void
  parseVerificationToken: () => void
  selectedSubscriptionPlan?: SubscriptionPlan
  setSelectedSubscriptionPlan: (subscriptionPlan?: SubscriptionPlan) => void
  selectedPaymentMethod?: PaymentMethod
  setSelectedPaymentMethod: (paymentMethod?: PaymentMethod) => void
}

export const AppContext = createContext<AppContextType>({} as AppContextType)

// ----------------------------------------------------------------------

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const deviceInfo = getBrowserInfo()
  const [searchParams] = useSearchParams()
  const { user, isAnonymousUserEnabled } = useAuthContext()
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<
    SubscriptionPlan | undefined
  >(undefined)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>(
    undefined,
  )

  const [verificationData, setVerificationData] = useState<VerificationData>({
    email: '',
    username: '',
    memberId: '',
    agreed: false,
    referralCode: '',
    subscribedOnUpdates: true,
    verificationStarted: false,
    isInitialized: false,
    subscribed: false,
    subscriptionFailed: false,
    isConsentAccepted: false,
    isParentAgeVerified: false,
    isStepperVisible: true,
  })
  const updateVerificationData = useCallback((data: UpdateVerificationDataPayload) => {
    setVerificationData((prevState) => ({ ...prevState, ...data }))
  }, [])

  const { data: memberData, error: memberError } = useQuery({
    retry: false,
    enabled: Boolean(verificationData.referralCode && user && isAnonymousUserEnabled),
    queryKey: ['member.findMemberByReferralCode', verificationData.referralCode],
    queryFn: () => getMemberByReferralCode(verificationData.referralCode),
  })

  useEffect(() => {
    if (memberError) {
      setVerificationData((prevState) => ({ ...prevState, isInitialized: true }))
    }
  }, [memberError])

  useEffect(() => {
    if (memberData) {
      let stepperParams = {}
      let skipConsentParams = {}
      const isConsentAccepted = memberData.consentStatus === ConsentStatus.ACCEPTED
      const isParentAgeVerified =
        memberData.parentAgeVerificationStatus === ParentAgeVerificationStatus.VERIFIED

      if (isConsentAccepted) {
        skipConsentParams = {
          subscribedOnUpdates: true,
        }
      }

      if (isConsentAccepted && isParentAgeVerified) {
        stepperParams = {
          isStepperVisible: false,
        }
      }

      setVerificationData((prevState) => ({
        ...prevState,
        ...stepperParams,
        ...skipConsentParams,
        isConsentAccepted,
        isParentAgeVerified,
        isInitialized: true,
      }))
    }
  }, [memberData])

  const parseVerificationToken = useCallback(() => {
    setVerificationData((prevState) => ({
      ...prevState,
      email: window.atob(searchParams.get('email') ?? ''),
      username: window.atob(searchParams.get('username') ?? ''),
      memberId: window.atob(searchParams.get('member-id') ?? ''),
      referralCode: window.atob(searchParams.get('kid-referral-code') ?? ''),
    }))
  }, [searchParams])

  const [otpData, setOtpData] = useState<OtpData>({
    otp: '',
    error: false,
  })
  const updateOtpData = useCallback((data: Partial<OtpData>) => {
    setOtpData((prevState) => ({ ...prevState, ...data }))
  }, [])

  const [stripeData, setStripeData] = useState<StripeData>({
    setupIntentId: '',
  })
  const updateStripeData = useCallback((data: Partial<StripeData>) => {
    setStripeData((prevState) => ({ ...prevState, ...data }))
  }, [])

  const [burgerMenuData, setBurgerMenuData] = useState<BurgerMenuData>({
    isOpened: false,
  })
  const updateBurgerMenuData = useCallback((data: Partial<BurgerMenuData>) => {
    setBurgerMenuData((prevState) => ({ ...prevState, ...data }))
  }, [])

  const memoizedValue = useMemo(
    () => ({
      otpData,
      deviceInfo,
      stripeData,
      updateOtpData,
      burgerMenuData,
      verificationData,
      updateStripeData,
      updateBurgerMenuData,
      selectedPaymentMethod,
      updateVerificationData,
      parseVerificationToken,
      selectedSubscriptionPlan,
      setSelectedPaymentMethod,
      setSelectedSubscriptionPlan,
    }),
    [
      otpData,
      deviceInfo,
      stripeData,
      updateOtpData,
      burgerMenuData,
      verificationData,
      updateStripeData,
      updateBurgerMenuData,
      selectedPaymentMethod,
      updateVerificationData,
      parseVerificationToken,
      selectedSubscriptionPlan,
      setSelectedPaymentMethod,
      setSelectedSubscriptionPlan,
    ],
  )

  return <AppContext.Provider value={memoizedValue}>{children}</AppContext.Provider>
}
