/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

const RobloxSolidIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx, fill: 'inherit', stroke: 'none' }} viewBox="0 0 18 18">
      <g clipPath="url(#clip0_16236_4098)">
        <path d="M4.15584 0.75L0.75 13.9965L13.9965 17.4023L17.4023 4.15584L4.15584 0.75ZM7.89669 6.87075L11.1071 7.69429L10.2836 10.9047L7.07314 10.0812L7.89669 6.87075Z" />
        <path
          d="M6.20102 12.3122C6.32821 12.3122 6.43133 12.2091 6.43133 12.0819C6.43133 11.9547 6.32821 11.8516 6.20102 11.8516C6.07382 11.8516 5.9707 11.9547 5.9707 12.0819C5.9707 12.2091 6.07382 12.3122 6.20102 12.3122Z"
          fill="url(#paint0_radial_16236_4098)"
          opacity="0.2"
        />
        <path
          d="M14.3875 7.61099C14.6458 7.61099 14.8551 7.40164 14.8551 7.14339C14.8551 6.88513 14.6458 6.67578 14.3875 6.67578C14.1293 6.67578 13.9199 6.88513 13.9199 7.14339C13.9199 7.40164 14.1293 7.61099 14.3875 7.61099Z"
          fill="url(#paint1_radial_16236_4098)"
          opacity="0.29"
        />
        <path
          d="M4.46336 9.65328C4.65994 9.65328 4.8193 9.49392 4.8193 9.29734C4.8193 9.10077 4.65994 8.94141 4.46336 8.94141C4.26678 8.94141 4.10742 9.10077 4.10742 9.29734C4.10742 9.49392 4.26678 9.65328 4.46336 9.65328Z"
          fill="url(#paint2_radial_16236_4098)"
          opacity="0.37"
        />
        <path
          d="M10.5211 17.132C10.6945 17.132 10.8352 16.9914 10.8352 16.818C10.8352 16.6445 10.6945 16.5039 10.5211 16.5039C10.3476 16.5039 10.207 16.6445 10.207 16.818C10.207 16.9914 10.3476 17.132 10.5211 17.132Z"
          fill="url(#paint3_radial_16236_4098)"
          opacity="0.27"
        />
      </g>
      <defs>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(15.2932 14.4006) scale(21.2319)"
          gradientUnits="userSpaceOnUse"
          id="paint0_radial_16236_4098"
          r="1">
          <stop stopColor="currentColor" stopOpacity="0.48" />
          <stop offset="0.9983" stopColor="currentColor" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(24.6733 5.0936) scale(21.2319 21.2319)"
          gradientUnits="userSpaceOnUse"
          id="paint1_radial_16236_4098"
          r="1">
          <stop stopColor="currentColor" stopOpacity="0.48" />
          <stop offset="0.9983" stopColor="currentColor" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(6.96303 6.46478) scale(15.0818 15.0818)"
          gradientUnits="userSpaceOnUse"
          id="paint2_radial_16236_4098"
          r="1">
          <stop stopColor="currentColor" stopOpacity="0.48" />
          <stop offset="0.9983" stopColor="currentColor" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(16.3711 11.412) scale(20.266)"
          gradientUnits="userSpaceOnUse"
          id="paint3_radial_16236_4098"
          r="1">
          <stop stopColor="currentColor" stopOpacity="0.48" />
          <stop offset="0.9983" stopColor="currentColor" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_16236_4098">
          <rect fill="currentColor" height="18" width="18" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default RobloxSolidIcon
