import { Form, FormSpy } from 'react-final-form'

import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Typography, InputAdornment } from '@mui/material'

import { useAppContext } from '@/hooks/useAppContext'
import { useImageSources } from '@/hooks/useImageSources'

import { captionColor } from '@/setup/theme'

import { logoStyles } from '@/pages/landing/Landing.styles'
import {
  gameReviewListHeaderLogo,
  gameReviewListHeaderForm,
  gameReviewListHeaderTitle,
  gameReviewListHeaderWrapper,
  gameReviewListHeaderContentWrapper,
} from '@/pages/gameReview/GameReviewListHeader.styles'

import Link from '@/components/Link'
import Image from '@/components/image/Image'
import { Form as FormTag } from '@/components/Tags'
import FormField from '@/components/forms/FormField'
import SearchIcon from '@/components/icons/SearchIcon'
import { handleLogoClick } from '@/components/utils/common'
import { FormFieldType } from '@/components/forms/constants'

type FormValues = {
  search: string
}

type GameReviewListHeaderProps = {
  setSearch: (search: string) => void
}
export const GameReviewListHeader = ({ setSearch }: GameReviewListHeaderProps) => {
  const {
    deviceInfo: { isMobile },
  } = useAppContext()
  const logoImageSource = useImageSources({
    sourceName: 'kinjo_logo',
  })

  const onSubmit = (values: FormValues) => {
    setSearch(values.search)
  }

  return (
    <Box sx={gameReviewListHeaderWrapper}>
      <Box sx={gameReviewListHeaderLogo}>
        {isMobile && logoImageSource.src && (
          <Image
            alt="kinjo logo"
            imgSx={logoStyles}
            onClick={handleLogoClick}
            sources={logoImageSource.sources}
            src={logoImageSource.src}
          />
        )}
      </Box>
      <Typography
        color={captionColor}
        mt={(theme) => (theme.device.isMobile ? '32px' : '0')}
        variant="bodyLarge">
        <Link href="https://kinjo.com/" rel="noopener noreferrer" sx={{ color: captionColor }}>
          Kinjo
        </Link>{' '}
        /{' '}
        <Typography color="common.darkBlue" variant="bodyLarge">
          Roblox Game Reviews{' '}
        </Typography>
      </Typography>
      <Typography sx={gameReviewListHeaderTitle} variant={isMobile ? 'large' : 'ultraLarge'}>
        Roblox Game Reviews
      </Typography>
      <Box
        sx={{
          maxWidth: '784px',
          display: 'flex',
          flexDirection: 'column',
          mx: 'auto',
          gap: '16px',
          mt: '16px',
        }}>
        <Typography color={captionColor} textAlign="center" variant="bodyLarge">
          Kinjo’s education and gaming experts evaluate Roblox games on a 500-point rubric to find
          real life skills kids can build while gaming.
        </Typography>
        <Typography color={captionColor} textAlign="center" variant="bodyLarge">
          Kids earn rewards for playing recommended games via the{' '}
          <Link
            href="https://apps.apple.com/us/app/kinjo-play-learn-earn/id6444465734?mt=8"
            rel="noopener noreferrer">
            Kinjo iOS app
          </Link>{' '}
          and parents are kept in the loop with a weekly{' '}
          <Link href="https://kinjo.com/kinjo-plus/" rel="noopener noreferrer">
            Kinjo Plus
          </Link>{' '}
          digest of their child’s Roblox gameplay.
        </Typography>
      </Box>

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form, values }) => (
          <FormTag onSubmit={handleSubmit} sx={gameReviewListHeaderContentWrapper}>
            <FormSpy
              onChange={({ values }) => {
                if (!values?.search) {
                  return setSearch('')
                }
                values?.search?.length >= 2 && setSearch(values?.search)
              }}
              subscription={{ values: true }}
            />
            <Box sx={gameReviewListHeaderForm}>
              <FormField
                endAdornment={
                  <InputAdornment position="end">
                    {values.search ? (
                      <IconButton
                        aria-label="Search Games"
                        onClick={() => {
                          form.reset()
                        }}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <IconButton aria-label="Search Games" type="submit">
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                name="search"
                placeholder="Search games"
                type={FormFieldType.TEXT}
                fullWidth
              />
            </Box>
          </FormTag>
        )}
      </Form>
    </Box>
  )
}
