const ROOTS_AUTH = '/auth'
const ROOTS_HOME = '/home'
const ROOTS_GAME_LIBRARY = '/game-library'
const ROOTS_ACCOUNT = '/account'
const ROOTS_VERIFICATION = '/verification'
const ROOTS_NOT_FOUND = '/404'

const path = (root: string, subLink: string) => {
  return `${root}${subLink}`
}

const ROOTS_REGISTER = path(ROOTS_AUTH, '/register')
const ROOTS_LOGIN = path(ROOTS_AUTH, '/login')

export const PATH_AUTH = {
  root: ROOTS_AUTH,

  login: ROOTS_LOGIN,
  otpLogin: path(ROOTS_LOGIN, '/otp'),

  register: ROOTS_REGISTER,
  otpRegister: path(ROOTS_REGISTER, '/otp'),
  registerInformation: path(ROOTS_REGISTER, '/information'),

  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
}

export const PATH_HOME = {
  root: ROOTS_HOME,
  gameLibrary: ROOTS_GAME_LIBRARY,
  NOT_FOUND: ROOTS_NOT_FOUND,
}

export const PATH_GAME_LIBRARY = {
  root: ROOTS_GAME_LIBRARY,
  gameReview: (universalId: string, selectedKid: string | number) =>
    path(ROOTS_GAME_LIBRARY, `/detail/${universalId}/${selectedKid}`),
  hiddenGames: path(ROOTS_GAME_LIBRARY, '/hidden'),
  allGamesByDomainSkill: (domainSkillId: string, selectedKid: string | number) =>
    path(ROOTS_GAME_LIBRARY, `/skill/${domainSkillId}/${selectedKid}`),
}

export const PATH_ACCOUNT = {
  root: ROOTS_ACCOUNT,
  details: path(ROOTS_ACCOUNT, '/details'),
  security: path(ROOTS_ACCOUNT, '/security'),
  paymentMethods: path(ROOTS_ACCOUNT, '/payment_methods'),
  newPaymentMethod: path(ROOTS_ACCOUNT, '/payment_methods/new'),
  contactSupport: path(ROOTS_ACCOUNT, '/contact_support'),
  suggestImprovement: path(ROOTS_ACCOUNT, '/suggest_improvement'),
  family: path(ROOTS_ACCOUNT, '/family'),
  addChild: path(ROOTS_ACCOUNT, '/family/add'),
  subscriptions: path(ROOTS_ACCOUNT, '/subscriptions'),
}
export const PATH_VERIFICATION = {
  root: ROOTS_VERIFICATION,
  intro: path(ROOTS_VERIFICATION, '/intro'),
  parent: path(ROOTS_VERIFICATION, '/parent'),
  cardConfirmation: path(ROOTS_VERIFICATION, '/card_confirmation'),
  success: path(ROOTS_VERIFICATION, '/success'),
  verified: path(ROOTS_VERIFICATION, '/verified'),
  connect: path(ROOTS_VERIFICATION, '/connect'),
  subscribed: path(ROOTS_VERIFICATION, '/subscribed'),
  subscriptionFail: path(ROOTS_VERIFICATION, '/subscription_fail'),
}

export const PATH_LANDINGS = {
  gameReviews: '/roblox-game-reviews',
  gameReview: (universalId: string) => `/roblox-game-reviews/${universalId}`,
}
