import axios from 'axios'

import { handleResponse } from './utils'

export const kinjoApi = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production' ? process.env.REACT_APP_KINJO_HOST_API_KEY : '/api/v1',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

kinjoApi.interceptors.response.use(handleResponse)
