import { DragEvent, ChangeEvent } from 'react'

import { FileUploaderFieldProps } from '@/components/forms/FormField'

export const maxAllowedMb = 25

export const getUploadedFiles = (
  e: DragEvent | ChangeEvent<HTMLInputElement>,
  isFileLimitReached: boolean,
) => {
  if (isFileLimitReached) {
    e.preventDefault()
    return
  }

  let uploadedFiles: File[] = []

  if ((e as ChangeEvent<HTMLInputElement>).target.files) {
    uploadedFiles = Array.from((e as ChangeEvent<HTMLInputElement>).target.files || [])
  } else if ((e as DragEvent).dataTransfer.files) {
    uploadedFiles = Array.from((e as DragEvent).dataTransfer.files)
  }

  if (!uploadedFiles.length) {
    e.preventDefault()
    return
  }

  return uploadedFiles
}

export const validateAllowedExtensions = (
  allowedExtensions: FileUploaderFieldProps['allowedExtensions'],
  fileName: string,
) => {
  if (allowedExtensions) {
    const parsedFileName = fileName.split('.')
    const fileExtension = parsedFileName[parsedFileName.length - 1]
      ? `.${parsedFileName[parsedFileName.length - 1]}`
      : ''

    if (!allowedExtensions.includes(fileExtension.toLowerCase())) {
      return false
    }
  }

  return true
}

export const validateMaxAllowedFileSize = (fileSize: number) => {
  const maxAllowedSize = maxAllowedMb * 1024 * 1024

  if (fileSize > maxAllowedSize) {
    return false
  }

  return true
}

export const checkIfNeedToContinueFilesProcessing = (
  filesLimit: FileUploaderFieldProps['filesLimit'],
  filesLength: number,
  newFilesLength: number,
  uploadedFilesLength: number,
  index: number,
) => {
  return typeof filesLimit === 'number'
    ? filesLength + newFilesLength < filesLimit && index < uploadedFilesLength
    : true
}
