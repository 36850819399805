import { FC, Suspense, LazyExoticComponent } from 'react'

import Loader from '@/components/Loader'

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
export const Loadable = (Component: LazyExoticComponent<FC<any>>) => (props: any) => (
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>
)
