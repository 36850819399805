import { useTranslation } from 'react-i18next'
import { FC, useMemo, useState, FocusEvent, useCallback } from 'react'

import { DatePicker as MuiDatePickerBase } from '@mui/x-date-pickers/DatePicker/DatePicker'
import {
  Zoom,
  IconButton,
  FormControl,
  FormHelperText,
  TextFieldProps,
  TextField as MuiTextField,
} from '@mui/material'

import { getProcessedFormErrorInfo } from '@/utils/getProcessedFormErrorInfo'

import InputLabel from './InputLabel'
import { getDatePickerStyles } from './DatePicker.styles'
import { getFormHelperTextStyles } from './TextInput.styles'
import { FormFieldDateInputComponentProps } from '../forms/FormField'
import DatePickerAdornmentIcon from '../icons/DatePickerAdornmentIcon'

type TextFieldAdornmentProps = {
  onClick: () => void
  disabled: boolean
}

const getTextFieldOverride = (adornmentProps: TextFieldAdornmentProps) => {
  return function TextFieldOverride(props: TextFieldProps) {
    const { onClick, disabled } = adornmentProps
    return (
      <MuiTextField
        {...props}
        InputProps={{
          ...props.InputProps,
          endAdornment: (
            <IconButton disabled={disabled} edge="end" onClick={onClick}>
              <DatePickerAdornmentIcon />
            </IconButton>
          ),
        }}
      />
    )
  }
}

interface DatePickerProps extends FormFieldDateInputComponentProps {
  fullWidth?: boolean
  placeholder?: string
}

const DatePicker: FC<DatePickerProps> = ({ input, meta, ...rest }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])
  const handleFocus = useCallback((e: FocusEvent<HTMLElement>) => e.target.blur(), [])
  const TextField = useMemo(
    () =>
      getTextFieldOverride({
        onClick: handleOpen,
        disabled: rest.disabled,
      }),
    [handleOpen, rest.disabled],
  )

  const { error, errorMessage } = getProcessedFormErrorInfo(meta, t)

  return (
    <FormControl error={error} fullWidth={rest.fullWidth} variant="standard">
      <InputLabel label={rest.label} />
      <MuiDatePickerBase
        disabled={rest.disabled}
        onChange={input.onChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        slotProps={{
          textField: {
            error,
            inputProps: {
              error,
              readOnly: true,
              className: error ? 'Mui-error' : '',
            },
            placeholder: rest.placeholder,
            onFocus: handleFocus,
          },
        }}
        slots={{ textField: TextField }}
        sx={getDatePickerStyles({ height: rest.height })}
        value={input.value || null}
      />
      <Zoom in={error} mountOnEnter unmountOnExit>
        <FormHelperText sx={getFormHelperTextStyles()}>{errorMessage}</FormHelperText>
      </Zoom>
    </FormControl>
  )
}

DatePicker.defaultProps = {
  disabled: false,
  fullWidth: false,
  placeholder: '',
}

export default DatePicker
