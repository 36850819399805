import { ButtonProps } from '@mui/material'

import { mergeSx } from '@/utils/mergeSx'

import {
  ButtonBase,
  primaryButtonStyles,
  outlinedButtonStyles,
  secondaryButtonStyles,
} from './Button.styles'

export enum BUTTON_VARIANTS {
  PRIMARY_BTN,
  SECONDARY_BTN,
  OUTLINED_BTN,
}

export interface CustomizedButtonProps extends Omit<ButtonProps, 'variant' | 'type'> {
  variant?: BUTTON_VARIANTS
  fullWidth?: boolean
}

const Button = (props: CustomizedButtonProps) => {
  if (props.variant === BUTTON_VARIANTS.OUTLINED_BTN) {
    return (
      <ButtonBase {...props} sx={mergeSx(outlinedButtonStyles, props.sx)} variant="outlined">
        {props.children}
      </ButtonBase>
    )
  }
  if (props.variant === BUTTON_VARIANTS.SECONDARY_BTN) {
    return (
      <ButtonBase {...props} sx={mergeSx(secondaryButtonStyles, props.sx)} variant="contained">
        {props.children}
      </ButtonBase>
    )
  }
  return (
    <ButtonBase {...props} sx={mergeSx(primaryButtonStyles, props.sx)} variant="contained">
      {props.children}
    </ButtonBase>
  )
}

Button.defaultProps = {
  type: 'button',
  variant: BUTTON_VARIANTS.PRIMARY_BTN,
  disabled: false,
  fullWidth: false,
}

export default Button
