import { lazy } from 'react'

import { Loadable } from '@/routes/Loadable'

export const LoginPage = Loadable(lazy(() => import('../pages/auth/login/Login')))
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/register/Register')))
export const HomePage = Loadable(lazy(() => import('../pages/home/Home')))
export const GameLibraryPage = Loadable(lazy(() => import('../pages/gameLibrary/GamesLibrary')))
export const GameLibraryLayout = Loadable(
  lazy(() => import('../pages/gameLibrary/GameLibraryLayout')),
)
export const HiddenGamesLibrary = Loadable(
  lazy(() => import('../pages/gameLibrary/HiddenGamesLibrary')),
)
export const AccountDetailsPage = Loadable(
  lazy(() => import('../pages/account/details/AccountDetails')),
)
export const AccountSecurityPage = Loadable(
  lazy(() => import('../pages/account/security/AccountSecurity')),
)
export const AccountPaymentMethodsPage = Loadable(
  lazy(() => import('../pages/account/paymentMethods/PaymentMethods')),
)
export const AccountContactSupportPage = Loadable(
  lazy(() => import('../pages/account/contactSupport/ContactSupport')),
)
export const AccountSuggestImprovementPage = Loadable(
  lazy(() => import('../pages/account/suggestImprovement/SuggestImprovement')),
)
export const AccountFamilyPage = Loadable(lazy(() => import('../pages/account/family/Family')))
export const AccountEditChildPage = Loadable(
  lazy(() => import('../pages/account/family/editChild/EditChild')),
)
export const AccountAddChildPage = Loadable(
  lazy(() => import('../pages/account/family/addChild/AddChild')),
)
export const SubscriptionsPage = Loadable(
  lazy(() => import('../pages/account/subscriptions/Subscriptions')),
)
export const AuthWrapper = Loadable(lazy(() => import('../pages/auth/authWrapper/AuthWrapper')))
export const OtpCode = Loadable(lazy(() => import('../pages/auth/OtpCode')))
export const ForgotPassword = Loadable(
  lazy(() => import('../pages/auth/forgotPassword/ForgotPassword')),
)

export const AuthGuard = Loadable(lazy(() => import('../setup/AuthGuard')))
// export const GuestGuard = Loadable(lazy(() => import('../setup/GuestGuard')))
export const PageLayout = Loadable(lazy(() => import('../components/layout/PageLayout')))
export const AccountPageLayout = Loadable(
  lazy(() => import('../components/layout/AccountPageLayout')),
)
export const RegisterInformation = Loadable(
  lazy(() => import('../pages/auth/register/RegisterInformation')),
)

export const NotFound = Loadable(lazy(() => import('../pages/notFound/NotFound')))
export const TempUnavailable = Loadable(
  lazy(() => import('../pages/tempUnavailable/TempUnavailable')),
)

export const VerificationPageLayout = Loadable(
  lazy(() => import('../components/layout/VerificationPageLayout')),
)
export const VerificationIntro = Loadable(
  lazy(() => import('../pages/verification/VerificationIntro')),
)
export const VerificationParent = Loadable(
  lazy(() => import('../pages/verification/VerificationParent')),
)
export const CardConfirmation = Loadable(
  lazy(() => import('../pages/verification/CardConfirmation')),
)
export const VerificationSuccess = Loadable(
  lazy(() => import('../pages/verification/success/VerificationSuccess')),
)
export const VerificationSuccessVerified = Loadable(
  lazy(() => import('../pages/verification/success/VerificationSuccessVerified')),
)
export const VerificationConnect = Loadable(
  lazy(() => import('../pages/verification/VerificationConnect')),
)
export const VerificationSubscribed = Loadable(
  lazy(() => import('../pages/verification/VerificationSubscribed')),
)
export const VerificationSubscriptionFail = Loadable(
  lazy(() => import('../pages/verification/VerificationSubscriptionFail')),
)
export const Landing = Loadable(lazy(() => import('../pages/landing/Landing')))
export const GameReviewDetail = Loadable(lazy(() => import('../pages/gameReview/GameReviewDetail')))

export const Questions = Loadable(lazy(() => import('../pages/landing/questions/Questions')))

export const NewsletterModal = Loadable(
  lazy(() => import('../pages/landing/freeApplication/NewsletterModal')),
)

export const FamilyPlatform = Loadable(
  lazy(() => import('../pages/landing/familyPlatform/FamilyPlatform')),
)
export const WeeklyInsights = Loadable(
  lazy(() => import('../pages/landing/weeklyInsights/WeeklyInsights')),
)
export const KidsComments = Loadable(
  lazy(() => import('../pages/landing/kidsComments/KidsComments')),
)
export const Stats = Loadable(lazy(() => import('../pages/landing/stats/Stats')))
export const ParentComments = Loadable(
  lazy(() => import('../pages/landing/parentComments/ParentComments')),
)
export const SubscriptionNew = Loadable(
  lazy(() => import('../pages/landing/subscriptionNew/Subscription')),
)

export const ChildAppModal = Loadable(
  lazy(() => import('../pages/landing/freeApplication/ChildAppModal')),
)
export const HowItWorks = Loadable(lazy(() => import('../pages/landing/howItWorks/HowItWorks')))
export const AllGamesByDomainSkill = Loadable(
  lazy(() => import('../pages/gameLibrary/AllGamesByDomainSkill')),
)

export const Section1 = lazy(() => import('@/pages/landing/section1/Section1'))
export const Section2 = lazy(() => import('@/pages/landing/section2/Section2'))
export const Section3 = lazy(() => import('@/pages/landing/section3/Section3'))
export const Section4 = lazy(() => import('@/pages/landing/section4/Section4'))
export const Section5 = lazy(() => import('@/pages/landing/section5/Section5'))
export const Section6 = lazy(() => import('@/pages/landing/section6/Section6'))
export const Section7 = lazy(() => import('@/pages/landing/section7/Section7'))
export const AppStoreDownload = lazy(
  () => import('@/pages/landing/appStoreDownload/AppStoreDownload'),
)
