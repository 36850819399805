import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ExpandMore } from '@mui/icons-material'
import {
  Zoom,
  MenuItem,
  InputBase,
  Typography,
  FormControl,
  FormHelperText,
  Select as SelectBase,
} from '@mui/material'

import { mergeSx } from '@/utils/mergeSx'
import { getProcessedFormErrorInfo } from '@/utils/getProcessedFormErrorInfo'

import InputLabel from './InputLabel'
import { FormFieldInputComponentProps } from '../forms/FormField'
import { getInputBaseStyles, getFormHelperTextStyles } from './TextInput.styles'
import { selectStyles, menuStyleProps, getTextHelperStyles } from './Select.styles'

type Option = {
  label: string
  value: string
}

interface SelectProps extends FormFieldInputComponentProps {
  options: Option[]
}

const Select: FC<SelectProps> = ({ input, meta, options, ...rest }) => {
  const { t } = useTranslation()

  const { id, height, variant, placeholder, textHelper, renderValue, ...selectBaseProps } = rest
  const { error, errorMessage } = getProcessedFormErrorInfo(meta, t)

  return (
    <FormControl error={error} fullWidth={rest.fullWidth} variant="standard">
      <InputLabel id={id} label={rest.label} />
      <SelectBase
        id={id}
        {...input}
        {...selectBaseProps}
        IconComponent={ExpandMore}
        MenuProps={menuStyleProps}
        displayEmpty={!!placeholder}
        error={error}
        input={<InputBase sx={mergeSx(selectStyles, getInputBaseStyles({ height, variant }))} />}
        renderValue={(value) => {
          const renderedCustomValue = renderValue?.(value)
          if (renderedCustomValue) {
            return renderedCustomValue
          }

          if (!value || !value.length) {
            return <Typography className="select-placeholder">{placeholder}</Typography>
          }

          return value
        }}>
        {options.map((option) => (
          <MenuItem key={`selectItem_${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </SelectBase>
      <Zoom in={!error && textHelper && (!meta.invalid || !meta.dirty)} mountOnEnter unmountOnExit>
        <FormHelperText sx={getTextHelperStyles()}>{textHelper}</FormHelperText>
      </Zoom>
      <Zoom in={error} mountOnEnter unmountOnExit>
        <FormHelperText sx={getFormHelperTextStyles()}>{errorMessage}</FormHelperText>
      </Zoom>
    </FormControl>
  )
}

export default Select
