import { useTranslation } from 'react-i18next'
import React, { useRef, useMemo, useState, useEffect, ChangeEvent } from 'react'

import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material'
import {
  Zoom,
  InputBase,
  IconButton,
  FormControl,
  InputAdornment,
  FormHelperText,
} from '@mui/material'

import { getProcessedFormErrorInfo } from '@/utils/getProcessedFormErrorInfo'

import InputLabel from './InputLabel'
import ErrorIcon from '../icons/ErrorIcon'
import SuccessIcon from '../icons/SuccessIcon'
import { FormFieldType } from '../forms/constants'
import { FormFieldComponentProps } from '../forms/FormField'
import {
  getInputBaseStyles,
  getTextHelperStyles,
  getFormHelperTextStyles,
  getFormHelperVerificationTextStyles,
  adornmentVerificationErrorIconStyles,
  adornmentVerificationSuccessIconStyles,
} from './TextInput.styles'

export enum INPUT_VARIANTS {
  BASE,
  LIGHT,
}

const TextInput: React.FC<FormFieldComponentProps> = ({ input, meta, ...rest }) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const containerRef = React.useRef(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  // check input type once at start. No need to update during render
  const isPasswordRef = useRef(rest.type === FormFieldType.PASSWORD)
  const {
    id,
    height,
    variant,
    verified,
    multiline,
    textHelper,
    onVerificationClick,
    ...inputBaseProps
  } = rest
  const isVerifiable = useMemo(() => typeof verified === 'boolean', [verified])

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const { error, errorMessage } = getProcessedFormErrorInfo(meta, t)
  const inputType = isPasswordRef.current
    ? !isPasswordRef.current || showPassword
      ? 'text'
      : 'password'
    : rest.type

  const endAdornment = useMemo(() => {
    if (isPasswordRef.current) {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}>
            {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
          </IconButton>
        </InputAdornment>
      )
    }

    if (isVerifiable) {
      if (!meta.dirty && !meta.invalid && verified) {
        return (
          <InputAdornment position="end">
            <SuccessIcon sx={adornmentVerificationSuccessIconStyles} />
          </InputAdornment>
        )
      }

      return (
        <InputAdornment position="end">
          <ErrorIcon sx={adornmentVerificationErrorIconStyles} />
        </InputAdornment>
      )
    }

    return null
  }, [isPasswordRef.current, isVerifiable, meta, verified, showPassword])
  const handleVerificationClick = () => {
    onVerificationClick(input.value)
  }

  const fitTargetHeightToValue = (target: HTMLDivElement) => {
    if (!multiline) {
      return
    }

    target.style.height = '1px'
    const targetOffset = target.scrollHeight - 4
    target.style.height = `${targetOffset}px`
  }
  useEffect(() => {
    if (!input.value && multiline && inputRef.current) {
      fitTargetHeightToValue(inputRef.current as HTMLDivElement)
    }
  }, [input.value, multiline])

  return (
    <FormControl error={error} fullWidth={rest.fullWidth} ref={containerRef} variant="standard">
      <InputLabel id={id} label={rest.label} />
      <InputBase
        components={{
          Input: multiline && 'textarea',
        }}
        endAdornment={endAdornment}
        id={id}
        inputRef={inputRef}
        onInput={(e: ChangeEvent<HTMLDivElement>) => fitTargetHeightToValue(e.target)}
        sx={getInputBaseStyles({ multiline, height, variant })}
        {...input}
        {...inputBaseProps}
        error={error}
        inputProps={{
          maxLength: rest.maxLength,
          autoCorrect: 'off',
          autoCapitalize: 'none',
          autoFocus: rest.autoFocus,
        }}
        type={inputType}
      />
      <Zoom
        in={onVerificationClick && isVerifiable && !meta.invalid && (!verified || meta.dirty)}
        mountOnEnter
        unmountOnExit>
        <FormHelperText
          onClick={handleVerificationClick}
          sx={getFormHelperVerificationTextStyles()}>
          {t('getVerificationCode')}
        </FormHelperText>
      </Zoom>
      <Zoom in={!error && textHelper && (!meta.invalid || !meta.dirty)} mountOnEnter unmountOnExit>
        <FormHelperText sx={getTextHelperStyles()}>{textHelper}</FormHelperText>
      </Zoom>
      <Zoom in={error} mountOnEnter unmountOnExit>
        <FormHelperText sx={getFormHelperTextStyles()}>{errorMessage}</FormHelperText>
      </Zoom>
    </FormControl>
  )
}

export default TextInput
