import { Theme, alpha } from '@mui/material'

export const selectStyles = {
  '& .MuiSelect-icon': {
    marginRight: '8px',
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    minHeight: 'unset',
    height: '20px',
    padding: '11px 32px 11px 16px',
  },
  '& .MuiSelect-select .select-placeholder': {
    opacity: '0.42',
  },
}

export const menuStyleProps = {
  PaperProps: {
    sx: {
      maxHeight: '320px',
      boxShadow: '1px 1px 15px 0px rgba(29, 45, 78, 0.10)',
      borderRadius: '16px',
      '& .MuiList-root': {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
      },
      '& .MuiMenuItem-root': {
        padding: '20px 24px',
        '&.Mui-selected': {
          backgroundColor: 'common.culturedPearl',
        },
      },
    },
  },
}

export const getFormHelperTextStyles = () => [
  {
    width: '100%',
    marginTop: '4px',
    display: 'block',
  },
  (theme: Theme) => ({
    ...theme.typography.caption,
  }),
]

export const getTextHelperStyles = () => [
  ...getFormHelperTextStyles(),
  {
    color: (theme: Theme) => alpha(theme.palette.common.darkBlue, 0.4),
  },
]
