/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

const NextArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx, fill: 'none', stroke: 'inherit' }} viewBox="0 0 32 32">
      <circle
        cx="16"
        cy="16"
        fill="#E4E4E4"
        r="16"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 0 32)"
      />
      <path
        clipRule="evenodd"
        d="M14.7069 21.7083C14.5269 21.8877 14.2854 21.9918 14.0315 21.9995C13.7775 22.0073 13.5301 21.9181 13.3396 21.7501C13.149 21.582 13.0296 21.3478 13.0055 21.0948C12.9814 20.8419 13.0544 20.5893 13.2099 20.3883L13.2929 20.2943L17.9199 15.6683L13.2929 11.0423C13.1207 10.8701 13.0173 10.641 13.002 10.398C12.9867 10.155 13.0606 9.91471 13.2099 9.72231L13.2929 9.62831C13.4649 9.45598 13.694 9.35237 13.937 9.3369C14.18 9.32143 14.4203 9.39518 14.6129 9.54431L14.7069 9.62831L20.0409 14.9613C20.213 15.1335 20.3165 15.3626 20.3317 15.6056C20.347 15.8486 20.2731 16.0889 20.1239 16.2813L20.0409 16.3753L14.7069 21.7083Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  )
}

export default NextArrowIcon
