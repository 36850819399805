import { FC, ReactNode } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import Checkbox from '@/components/Checkbox'
import Select from '@/components/inputs/Select'
import RadioGroup from '@/components/inputs/RadioGroup'
import DatePicker from '@/components/inputs/DatePicker'
import FileUploader from '@/components/inputs/FileUploader'
import TextInput, { INPUT_VARIANTS } from '@/components/inputs/TextInput'

import { FormFieldType } from './constants'

export type BaseFormFieldProps = {
  id?: string
  name: string
  placeholder?: string
  label?: string | ReactNode
  fullWidth?: boolean
  parse?: (value: string) => string | undefined
  maxLength?: number
  disabled?: boolean
  verified?: boolean
  textHelper?: string
  onVerificationClick?: (source: string) => void
  multiline?: boolean
  height?: number
  variant?: INPUT_VARIANTS
  endAdornment?: ReactNode
  autoFocus?: boolean
}

type SelectFormFieldProps = BaseFormFieldProps & {
  type: FormFieldType.SELECT
  options: Array<{ label: string; value: string }>
  multiple?: boolean
  renderValue?: (value: string | string[]) => ReactNode | string
}

type RadioGroupFormFieldProps = BaseFormFieldProps & {
  type: FormFieldType.RADIO_GROUP
  options: Array<{ label: string; value: string }>
}

export type FileUploaderFieldProps = BaseFormFieldProps & {
  type: FormFieldType.FILE_UPLOADER
  filesLimit?: number
  onFilesChange: (formData: FormData) => void
  allowedExtensions?: string[]
}

type OtherFormFieldProps = BaseFormFieldProps & {
  type: Exclude<FormFieldType, FormFieldType.SELECT>
}

type FormFieldProps =
  | SelectFormFieldProps
  | RadioGroupFormFieldProps
  | FileUploaderFieldProps
  | OtherFormFieldProps

export type FormFieldInputComponentProps = FieldRenderProps<string | undefined>
export type FormFieldDateInputComponentProps = FieldRenderProps<Date | null | undefined>
export type FormFieldCheckboxComponentProps = FieldRenderProps<boolean>
export type FormFieldComponentProps =
  | FormFieldInputComponentProps
  | FormFieldDateInputComponentProps
  | FormFieldCheckboxComponentProps

const FormField: FC<FormFieldProps> = (props) => {
  const {
    id,
    type,
    name,
    placeholder,
    label,
    fullWidth,
    parse,
    maxLength,
    disabled,
    verified,
    textHelper,
    onVerificationClick,
    multiline,
    height,
    variant,
    endAdornment,
    autoFocus,
  } = props

  const getFormComponent = (type: FormFieldType, formProps: FormFieldComponentProps) => {
    switch (type) {
      case FormFieldType.TEXT:
      case FormFieldType.EMAIL:
      case FormFieldType.PHONE:
      case FormFieldType.PASSWORD:
        const { input, meta } = formProps as FormFieldInputComponentProps
        return (
          <TextInput
            autoFocus={autoFocus}
            disabled={disabled}
            endAdornment={endAdornment}
            fullWidth={fullWidth}
            height={height}
            id={id}
            input={input}
            label={label}
            maxLength={maxLength}
            meta={meta}
            multiline={multiline}
            onVerificationClick={onVerificationClick}
            placeholder={placeholder}
            textHelper={textHelper}
            type={type}
            variant={variant}
            verified={verified}
          />
        )
      case FormFieldType.SELECT: {
        const { options, multiple, renderValue } = props as SelectFormFieldProps
        const { input, meta } = formProps as FormFieldInputComponentProps
        return (
          <Select
            disabled={disabled}
            fullWidth={fullWidth}
            height={height}
            id={id}
            input={input}
            label={label}
            maxLength={maxLength}
            meta={meta}
            multiple={multiple}
            options={options}
            placeholder={placeholder}
            renderValue={renderValue}
            textHelper={textHelper}
            variant={variant}
          />
        )
      }
      case FormFieldType.DATE_PICKER: {
        const { input, meta } = formProps as FormFieldDateInputComponentProps
        return (
          <DatePicker
            disabled={disabled}
            fullWidth={fullWidth}
            input={input}
            label={label}
            meta={meta}
            placeholder={placeholder}
          />
        )
      }
      case FormFieldType.FILE_UPLOADER: {
        const { onFilesChange, allowedExtensions, filesLimit } = props as FileUploaderFieldProps
        const { input, meta } = formProps as FormFieldInputComponentProps
        return (
          <FileUploader
            allowedExtensions={allowedExtensions}
            disabled={disabled}
            filesLimit={filesLimit}
            fullWidth={fullWidth}
            input={input}
            label={label}
            meta={meta}
            onFilesChange={onFilesChange}
          />
        )
      }
      case FormFieldType.RADIO_GROUP: {
        const { options } = props as RadioGroupFormFieldProps
        const { input, meta } = formProps as FormFieldInputComponentProps
        return (
          <RadioGroup
            disabled={disabled}
            fullWidth={fullWidth}
            id={id}
            input={input}
            label={label}
            meta={meta}
            options={options}
          />
        )
      }
      case FormFieldType.CHECKBOX: {
        const { input } = formProps as FormFieldCheckboxComponentProps
        return (
          <Checkbox
            checked={input.value}
            disabled={disabled}
            fullWidth={fullWidth}
            id={id}
            label={label}
            onChange={() => input.onChange(!input.value)}
          />
        )
      }
      default: {
        const { input, meta } = formProps as FormFieldInputComponentProps
        return (
          <TextInput
            disabled={disabled}
            fullWidth={fullWidth}
            height={height}
            id={id}
            input={input}
            label={label}
            maxLength={maxLength}
            meta={meta}
            onVerificationClick={onVerificationClick}
            placeholder={placeholder}
            textHelper={textHelper}
            type={type}
            variant={variant}
            verified={verified}
          />
        )
      }
    }
  }

  return (
    <Field name={name} parse={parse} allowNull>
      {({ input, meta }) => getFormComponent(type, { input, meta })}
    </Field>
  )
}

export default FormField
