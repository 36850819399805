import { Theme, styled, ButtonProps, Button as MuiButton } from '@mui/material'

import { LINEAR_GRADIENT_RED } from '@/setup/theme'

export const ButtonBase = styled(MuiButton)<ButtonProps>((props) => ({
  width: props.fullWidth ? '100%' : 'fit-content',
  height: '44px',
  fontSize: '17px',
  boxShadow: 'none',
  lineHeight: '22px',
  borderRadius: '12px',
  textTransform: 'none',
  gap: '8px',
  '& .MuiButton-startIcon': {
    gap: '8px',
    margin: 0,

    // marginRight: 'auto',
    // position: 'absolute',
    // width: '100%',
    // paddingLeft: '24px',
  },
  '& .MuiTouchRipple-root': {
    opacity: 0.3,
  },
}))

export const primaryButtonStyles = {
  background: LINEAR_GRADIENT_RED,
  transition: 'opacity .3s ease-out, scale .3s ease-out',
  '&:hover': {
    scale: '1.02',
    background: 'unset',
    boxShadow: 'none',
    backgroundColor: '#E11A4F',
  },
  ':disabled': {
    opacity: 0.4,
    color: 'white',
  },
}

export const secondaryButtonStyles = [
  {},
  (theme: Theme) => ({
    backgroundColor: theme.palette.common.darkBlue,
    transition: 'opacity .3s ease-out, scale .3s ease-out',
    '&:hover': {
      backgroundColor: '#313C48',
      scale: '1.02',
      boxShadow: 'none',
    },
    ':disabled': {
      opacity: 0.4,
      color: 'white',
      backgroundColor: theme.palette.common.darkBlue,
    },
  }),
]

export const outlinedButtonStyles = [
  {
    backgroundColor: 'white',
    transition: 'opacity .3s ease-out, scale .3s ease-out',
    ':disabled': {
      opacity: 0.4,
    },
    '&:hover': {
      backgroundColor: '#F5F5F5',
      scale: '1.02',
      boxShadow: 'none',
    },
  },
  (theme: Theme) => ({
    border: `1px solid ${theme.palette.common.light}`,
    color: theme.palette.common.darkBlue,
    '&:hover': {
      border: `1px solid ${theme.palette.common.light}`,
    },
  }),
]
