import { Outlet, Navigate } from 'react-router-dom'

import { PATH_ACCOUNT } from '@/routes/paths'

import { useAuthContext } from '@/hooks/useAuthContext'
import { useLocalStorage } from '@/hooks/useLocalStorage'

const GuestGuard = () => {
  const { isAuthenticated, isInitialized } = useAuthContext()
  const [requestedLocation, setRequestedLocation] = useLocalStorage<string | null>('requestedPath')

  if (isAuthenticated) {
    setRequestedLocation(null)
    return <Navigate to={requestedLocation || PATH_ACCOUNT.family} replace />
  }

  if (!isInitialized) {
    return <span>Loading...</span>
  }

  return <Outlet />
}

export default GuestGuard
