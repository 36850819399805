/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

const SuccessIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ fill: 'none', stroke: 'inherit', ...props.sx }}>
      <path
        d="M8.72656 12.1041L11.3243 14.3901L15.2729 9.61023"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.7"
      />
      <circle cx="12.0001" cy="12" r="9.36389" strokeWidth="1.7" />
    </SvgIcon>
  )
}

export default SuccessIcon
