import { TFunction } from 'i18next'
import { FieldMetaState } from 'react-final-form'

export const getProcessedFormErrorInfo = (meta: FieldMetaState<unknown>, t: TFunction) => {
  if (!meta || !t) {
    return { error: false, errorMessage: '' }
  }
  if (!meta.dirty && !meta.submitFailed && !meta.submitFailed && !meta.visited) {
    return { error: false, errorMessage: '' }
  }

  if (meta.active && !meta.modified) {
    return { error: false, errorMessage: '' }
  }

  const currentError =
    meta.submitError && !meta.dirtySinceLastSubmit ? meta.submitError : meta.error
  const error = Boolean(currentError)
  const errorMessage = currentError
    ? typeof currentError === 'string'
      ? t(currentError)
      : (t(currentError.key, currentError.args) as string)
    : ''

  return { error, errorMessage }
}

type FormStateProps = {
  submitting: boolean
  pristine: boolean
  hasValidationErrors: boolean
  hasSubmitErrors: boolean
  dirtySinceLastSubmit: boolean
}
export const getCtaDisableState = (formStateProps: FormStateProps) =>
  formStateProps.submitting ||
  formStateProps.pristine ||
  formStateProps.hasValidationErrors ||
  (formStateProps.hasSubmitErrors && !formStateProps.dirtySinceLastSubmit)
