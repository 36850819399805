/* eslint-disable max-len */
import { SvgIcon, SvgIconProps } from '@mui/material'

const CheckboxIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx={{ ...props.sx, fill: 'none' }}>
      <rect
        height="17.6"
        rx="3.6"
        stroke="#17222F"
        strokeOpacity="0.4"
        strokeWidth="2.4"
        width="17.6"
        x="3.2"
        y="3.2"
      />
    </SvgIcon>
  )
}

export default CheckboxIcon
