import { alpha, Theme } from '@mui/material'

export const familyWrapperStyles = [
  {
    width: '100%',
    display: 'flex',
    borderRadius: 'inherit',
    flexDirection: 'column',
  },
  (theme: Theme) =>
    theme.device.isMobile
      ? {
          flex: 1,
          padding: '24px 24px 32px',
        }
      : { padding: '56px 48px' },
]

export const familyWelcomeTitleWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}

export const familyWelcomeTitleStyles = {
  color: 'common.white',
}

export const familyWelcomeTypeTitleStyles = {
  color: '#FFD25D',
}

export const familyWelcomeTextStyles = [
  {
    pt: '16px',
    color: 'common.white',
    textAlign: 'center',
  },
  (theme: Theme) => (theme.device.isMobile ? {} : { px: '88px' }),
]

export const familyWelcomeConfigureGameListWrapperStyles = [
  {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  (theme: Theme) => (theme.device.isMobile ? { py: '24px' } : { p: '24px 0 32px 0' }),
]

export const familyWelcomeConfigureGameListButtonStyles = {
  minWidth: '300px',
}

export const familyTitleWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

export const familyTitleStyles = [
  {
    color: 'common.white',
    textShadow: (theme: Theme) => `0px 0px 10px ${alpha(theme.palette.common.darkBlue, 0.24)}`,
  },
  (theme: Theme) => (theme.device.isMobile ? { paddingBottom: '24px' } : { paddingBottom: '32px' }),
]
