import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { PATH_ACCOUNT, PATH_GAME_LIBRARY } from '@/routes/paths'

import { useLocalStorage } from '@/hooks/useLocalStorage'

import { getAge } from '@/utils/time'

import { captionColor } from '@/setup/theme'

import UserIcon from '@/components/UserIcon'
import Button, { BUTTON_VARIANTS } from '@/components/Button'
import BonusSolidIcon from '@/components/icons/BonusSolidIcon'
import KinjoSolidIcon from '@/components/icons/KinjoSolidIcon'
import RobloxSolidIcon from '@/components/icons/RobloxSolidIcon'

import { Gender, FamilyMember } from '@/types/member'

import {
  childAvatarStyles,
  childCardSeparator,
  childCardWrapperStyles,
  childDetailsWrapperStyles,
  childDetailsIconWrapperStyles,
  childDetailsInfoWrapperStyles,
  childCardButtonsWrapperStyles,
  childCardLeftInfoWrapperStyles,
  childCardRightInfoWrapperStyles,
  childCardPersonalDataWrapperStyles,
  childCardPersonalInfoWrapperStyles,
} from './ChildCard.styles'

interface ChildCardProps {
  child: FamilyMember
  hiddenGamesCount?: number
}

export const getChildIconUrl = (gender?: Gender) => {
  switch (gender) {
    case Gender.MALE:
      return '/img/child_male_avatar.svg'
    case Gender.FEMALE:
      return '/img/child_female_avatar.svg'
    default:
      return ''
  }
}

const childInfo: { dataKey: keyof FamilyMember; labelKey: string; icon: ReactNode }[] = [
  {
    dataKey: 'referralCode',
    labelKey: 'referralCode',
    icon: <BonusSolidIcon sx={{ width: '100%', height: '100%' }} />,
  },
  {
    dataKey: 'userName',
    labelKey: 'kinjoUsername',
    icon: <KinjoSolidIcon sx={{ width: '100%', height: '100%' }} />,
  },
  {
    dataKey: 'robloxUserName',
    labelKey: 'robloxUsername',
    icon: <RobloxSolidIcon sx={{ width: '100%', height: '100%' }} />,
  },
]

const ChildCard: FC<ChildCardProps> = ({ child, hiddenGamesCount }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [, setSelectedKid] = useLocalStorage('selectedKid', '')
  const handleEditChildClick = () => {
    navigate(`${PATH_ACCOUNT.family}/edit/${child.id}`)
  }

  const handleHiddenGamesClick = () => {
    setSelectedKid(String(child.id))
    navigate(PATH_GAME_LIBRARY.hiddenGames)
  }

  return (
    <Box sx={childCardWrapperStyles}>
      <Box sx={childCardLeftInfoWrapperStyles}>
        <Box sx={childCardPersonalInfoWrapperStyles}>
          <UserIcon
            fullName={child.firstName}
            iconUrl={getChildIconUrl(child.gender)}
            sx={childAvatarStyles}
          />
          <Box sx={childCardPersonalDataWrapperStyles}>
            <Typography variant="title2">{child.firstName}</Typography>
            <Typography color="common.gray" variant="body1">
              {t('age')}{' '}
              {getAge({ date: child.birthDate, ageProvidedByKid: child.ageProvidedByKid }) || '-'}
            </Typography>
          </Box>
        </Box>
        <Box sx={childCardButtonsWrapperStyles}>
          <Button onClick={handleEditChildClick} variant={BUTTON_VARIANTS.OUTLINED_BTN} fullWidth>
            {t('edit')}
          </Button>
          <Button onClick={handleHiddenGamesClick} variant={BUTTON_VARIANTS.OUTLINED_BTN} fullWidth>
            {t('hiddenGamesNumber', { number: hiddenGamesCount })}
          </Button>
        </Box>
      </Box>
      <Box sx={childCardSeparator}></Box>
      <Box sx={childCardRightInfoWrapperStyles}>
        {childInfo.map((item) => (
          <Box key={item.dataKey} sx={childDetailsWrapperStyles}>
            <Box sx={childDetailsIconWrapperStyles}>{item.icon}</Box>
            <Box sx={childDetailsInfoWrapperStyles}>
              <Typography color={captionColor} variant="caption">
                {t(item.labelKey)}
              </Typography>
              <Typography variant="body1med">{child[item.dataKey] ?? '-'}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default ChildCard
