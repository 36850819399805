import { Theme } from '@mui/material'

export const gameReviewWrapper = {
  background: "url('/img/landing/topGames_background.webp')",
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  backfaceVisibility: 'hidden',
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  display: 'flex',
  overflow: 'hidden',
  overflowY: 'auto',
  flexDirection: 'column',
  backgroundColor: '#F1F2FC',
  alignItems: 'center',
}

export const gameReviewContentWrapper = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  minWidth: 200,
  maxWidth: 1188,
  width: '100%',
  borderRadius: '24px',
  backgroundColor: 'common.white',
  mb: '47px',
  boxShadow: '1px 1px 15px 0px rgba(29, 45, 78, 0.10)',
}

export const gameReviewListContentWrapper = [
  {
    ...gameReviewContentWrapper,
    justifyContent: 'flex-start',
  },
  (theme: Theme) =>
    theme.device.isMobile ? { p: '32px 0 50px 9px' } : { p: '44px 112px 44px 97px' },
]

export const appStoreDownloadWrapper = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}

export const appStoreDownloadContentWrapper = { maxWidth: '948px', width: '100%', mt: '80px' }

export const questionsWrapper = {
  display: 'flex',
  justifyContent: 'center',
  p: '0 24px 47px 24px',
  width: '100%',
}

export const gameReviewContentWrapperStyles = [
  (theme: Theme) =>
    theme.device.isMobile
      ? { p: '32px 24px' } // TODO validate
      : { p: '48px 200px' },
]
