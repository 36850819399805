import { Theme } from '@mui/material'

export const gameReviewListHeaderTitle = [
  { textAlign: 'center' },
  (theme: Theme) => (theme.device.isMobile ? { mt: '78px' } : { mt: '48px' }),
]

export const gameReviewListHeaderWrapper = [
  { display: 'flex', flexDirection: 'column', pl: '15px' },
  (theme: Theme) => (theme.device.isMobile ? { pr: '24px' } : { pr: '0' }),
]

export const gameReviewListHeaderLogo = { display: 'flex', width: '100%', justifyContent: 'center' }

export const gameReviewListHeaderContentWrapper = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  mt: '32px',
}

export const gameReviewListHeaderForm = { maxWidth: '540px', width: '100%' }
