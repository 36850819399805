import { Theme, alpha } from '@mui/material'

import { INPUT_VARIANTS } from './TextInput'

export const inputBorderWidth = 1
export const yInputPaddingWidth = 11

export const getFormHelperTextStyles = () => [
  {
    width: '100%',
    marginTop: '4px',
    display: 'block',
  },
  (theme: Theme) => ({
    ...theme.typography.caption,
  }),
]

export const getFormHelperVerificationTextStyles = () => [
  ...getFormHelperTextStyles(),
  {
    color: 'common.blue',
    cursor: 'pointer',
    textAlign: 'right',
  },
]

export const getTextHelperStyles = () => [
  ...getFormHelperTextStyles(),
  {
    color: (theme: Theme) => alpha(theme.palette.common.darkBlue, 0.4),
  },
]

const getInputStylesByVariant = (variant: INPUT_VARIANTS, theme: Theme) => {
  switch (variant) {
    case INPUT_VARIANTS.LIGHT:
      return {
        color: theme.palette.common.darkBlue,
        disabledColor: alpha(theme.palette.common.darkBlue, 0.4),
        backgroundColor: theme.palette.common.white,
      }
    case INPUT_VARIANTS.BASE:
    default:
      return {
        color: theme.palette.common.darkBlue,
        disabledColor: theme.palette.common.gray,
        backgroundColor: theme.palette.common.culturedPearl,
      }
  }
}

export const getInputBaseStyles = ({
  height = 44,
  variant = INPUT_VARIANTS.BASE,
  multiline = false,
}: {
  height?: number
  variant?: INPUT_VARIANTS
  multiline?: boolean
}) => [
  {
    borderRadius: '12px',
    'label + &': {
      marginTop: '28px',
    },
    '& .MuiInputAdornment-root': {
      maxHeight: `${height - inputBorderWidth * 2}px`,
      marginLeft: 0,
      height: `${height - inputBorderWidth * 2}px`,
    },
    '& .MuiInputBase-input': {
      ...(multiline
        ? {
            width: '100%',
            resize: 'none',
            minHeight: `${height - inputBorderWidth * 2}px`,
            border: 'none',
            outline: 0,
            overflow: 'hidden',
          }
        : { height: `${height - inputBorderWidth * 2 - yInputPaddingWidth * 2}px` }),
      borderRadius: '12px',
      fontWeight: 450,
      fontSize: '16px',
      lineHeight: '24px',
      padding: `${yInputPaddingWidth}px 16px`,
      '&.Mui-disabled': {
        cursor: 'not-allowed',
      },
    },
  },
  (theme: Theme) => {
    const { color, disabledColor, backgroundColor } = getInputStylesByVariant(variant, theme)

    return {
      border: `${inputBorderWidth}px solid ${theme.palette.common.culturedPearl}`,
      transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
      backgroundColor,
      ':hover': {
        borderColor: alpha(theme.palette.common.darkBlue, 0.6),
      },
      ':focus-within': {
        boxShadow: `${theme.palette.common.darkBlue} 0 0 0 1px`,
        borderColor: theme.palette.common.darkBlue,
        '&.Mui-error': {
          borderColor: theme.palette.common.newRed,
          boxShadow: `${theme.palette.common.newRed} 0 0 0 1px`,
        },
      },
      '&.Mui-error': {
        borderColor: theme.palette.common.newRed,
      },
      '&.Mui-disabled': {
        borderColor: alpha(theme.palette.common.culturedPearl, 0.5),
        backgroundColor: alpha(backgroundColor, 0.5),
      },
      '& .MuiInputBase-input': {
        fontFamily: multiline ? theme.typography.fontFamily : 'inherit',
        backgroundColor,
        color,
        '&.Mui-disabled': {
          color: disabledColor,
          backgroundColor: alpha(backgroundColor, 0.5),
        },
      },
    }
  },
]

export const adornmentVerificationErrorIconStyles = {
  color: 'common.newRed',
  stroke: (theme: Theme) => theme.palette.common.newRed,
  marginRight: '16px',
}

export const adornmentVerificationSuccessIconStyles = {
  stroke: (theme: Theme) => theme.palette.common.green,
  marginRight: '16px',
}
