import { useState } from 'react'
import { useParams, useNavigate, useOutletContext } from 'react-router'

import { Box, Typography, IconButton } from '@mui/material'

import { useSearchGame } from '@/api/hooks/useGameReview'
import { SEARCH_GAME_LIMIT, SEARCH_GAME_LIMIT_MAX } from '@/api/games'

import { PATH_GAME_LIBRARY } from '@/routes/paths'

import { useAppContext } from '@/hooks/useAppContext'

import { GameList } from '@/pages/gameReview/GameList'
import {
  getArrowCircleStyles,
  getArrowCircleAllGamesStyles,
} from '@/pages/gameReview/kinjoTools/KinjoTools.styles'

import NextArrowIcon from '@/components/icons/NextArrowIcon'

type GameDirectoryProps = {
  domainSkillName: string
  domainSkillId: string
  isShowAllGames?: boolean
  urlBuilder?: (universeId: string, selectedKid: string | number) => string
  randomSeed?: number
}

export const GameDirectory = ({
  domainSkillName,
  domainSkillId,
  randomSeed,
  isShowAllGames,
  urlBuilder,
}: GameDirectoryProps) => {
  const [index, setIndex] = useState(0)
  const navigate = useNavigate()
  const {
    deviceInfo: { isMobile },
  } = useAppContext()
  const { selectedKid: contextSelectedKid } =
    useOutletContext<{
      selectedKid: string | number
    }>() ?? {}

  const { selectedKid: paramsSelectedKid } = useParams()
  const selectedKid = contextSelectedKid ?? paramsSelectedKid

  const { data, isPending } = useSearchGame({
    filterBy: {
      domainSkillIds: [domainSkillId],
    },
    sortBy: {
      random: true,
      randomSeed: randomSeed,
    },
    offset: index,
    limit: isMobile ? SEARCH_GAME_LIMIT_MAX : SEARCH_GAME_LIMIT,
  })

  const isDisabledPrevPage = isPending || index - SEARCH_GAME_LIMIT < 0
  const isDisabledNextPage =
    isPending || !data?.totalCount || index + SEARCH_GAME_LIMIT >= data?.totalCount

  const handlePrevClick = () => {
    setIndex(index - SEARCH_GAME_LIMIT)
  }

  const handleNextClick = () => {
    setIndex(index + SEARCH_GAME_LIMIT)
  }

  const handleShowAllClick = () => {
    navigate(PATH_GAME_LIBRARY.allGamesByDomainSkill(domainSkillId, Number(selectedKid)))
  }

  if (!data?.totalCount) {
    return null
  }

  return (
    <Box>
      <Box
        sx={{
          p: '8px 0 8px 15px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box>
          <Typography variant="title2">{domainSkillName}</Typography>
          {isShowAllGames && (
            <IconButton aria-label="all games page" onClick={handleShowAllClick}>
              <NextArrowIcon sx={getArrowCircleAllGamesStyles(false, true)} />
            </IconButton>
          )}
        </Box>

        {!isMobile && (
          <Box>
            <IconButton
              aria-label="prev page of kids comments"
              disabled={isDisabledPrevPage}
              onClick={handlePrevClick}
              sx={{}}>
              <NextArrowIcon sx={getArrowCircleStyles(isDisabledPrevPage, false)} />
            </IconButton>
            <IconButton
              aria-label="next page of kids comments"
              disabled={isDisabledNextPage}
              onClick={handleNextClick}
              sx={{}}>
              <NextArrowIcon sx={getArrowCircleStyles(isDisabledNextPage, true)} />
            </IconButton>
          </Box>
        )}
      </Box>
      <GameList games={data?.gamePreviews || []} urlBuilder={urlBuilder} />
    </Box>
  )
}
